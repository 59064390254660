import React, { useState, useContext, useCallback } from 'react';
import PolicyItem from './components/policy_item';  
import PolicyDetailModal from './components/policy_detail_modal';  
import DeleteConfirmationModal from '../../../reusable/modals/delete_confirmation_modal';
import { get, post, put, del } from '../../../../utils/api'; 
import { OrgContext } from '../../../../utils/context'; 
import { M3BodyMedium,  } from '../../../reusable/TextStyles'; 
import { isOwner } from '../../../../utils/auth';

const PoliciesTab = ({ addDialogOpen, setAddDialogOpen, setAlert }) => {
    const [policies, setPolicies] = useState([]);
    const org = useContext(OrgContext);
    const org_id = org ? org.organisation_id : null;
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleEditClick = (policy) => {
        setSelectedPolicy(policy);
        setEditDialogOpen(true);
    };

    const fetchPolicies = useCallback(() => {
        setIsLoading(true);
        get(`/manage/organisation/${org_id ?? 0}/policies`)
            .then((res) => {
                setPolicies(res.policies);
                setIsLoading(false);
            })
            .catch((error) => {
                setAlert(error.toString(), 'error');
                setIsLoading(false);
            });
    }, [org_id, setAlert]);

    const openDeleteDialog = () => { // Fix: Remove unused parameter
        setEditDialogOpen(false);
        setDeleteDialogOpen(true);
    };

    const handleDeleteClick = () => { // Fix: Remove unused parameter
        del(`/manage/organisation/${org_id ?? 0}/policies/${selectedPolicy.id}`)
            .then(() => {
                fetchPolicies();
                setAlert('Policy deleted successfully', 'success');
            })
            .catch((error) => {
                setAlert(error.toString(), 'error');
            });
        setDeleteDialogOpen(false);
    };

    const handleDialogClose = () => {
        setEditDialogOpen(false);
        setAddDialogOpen(false);
        setDeleteDialogOpen(false);
    };

    const handleEditPolicy = (policy) => {
        put(`/manage/organisation/${org_id ?? 0}/policies/${policy.id}`, policy)
            .then(() => {
                fetchPolicies();
                setAlert('Policy edited successfully', 'success');
            })
            .catch((error) => {
                setAlert(error.toString(), 'error');
            });
        setEditDialogOpen(false);
    };

    const handleAddingPolicy = (policy) => {
        post(`/manage/organisation/${org_id ?? 0}/policies`, policy)
            .then(() => {
                fetchPolicies();
                setAlert('Policy added successfully', 'success');
            })
            .catch((error) => {
                setAlert(error.toString(), 'error');
            });
        setAddDialogOpen(false);
    };

    React.useEffect(() => { // Fix: Remove unnecessary 'React' prefix
        fetchPolicies();
    }, [fetchPolicies]);

    return (
        <div>
            {isLoading ? (
                <div>
                    {[0, 1, 2, 3].map((index) => (
                        <PolicyItem key={index}></PolicyItem>
                    ))}
                </div>
            ) : policies.length === 0 ? (
                <M3BodyMedium
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60vh'
                    }}
                >
                    {' '}
                    No policies found{' '}
                </M3BodyMedium>
            ) : (
                policies.map((policy) => (
                    <PolicyItem
                        key={policy.id}
                        name={policy.name}
                        content={policy.content}
                        onEditClick={() => handleEditClick(policy)}
                    />
                ))
            )}
            <DeleteConfirmationModal
                open={deleteDialogOpen}
                onClose={handleDialogClose}
                onDelete={handleDeleteClick}
            />

            <PolicyDetailModal
                open={editDialogOpen}
                onClose={handleDialogClose}
                onSave={handleEditPolicy}
                selectedPolicy={selectedPolicy}
                onDelete={openDeleteDialog}
                editable={isOwner(org)}
            />
            <PolicyDetailModal
                open={addDialogOpen}
                onClose={handleDialogClose}
                onSave={handleAddingPolicy}
                isAddModal={true}
                selectedPolicy={{}}
            />
        </div>
    );
};
export default PoliciesTab; 