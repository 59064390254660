import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { M3TitleLarge } from "../../reusable/TextStyles";

/// Shift error page with button to return to dashboard
const ErrorShiftPage = () => {
    const navigate = useNavigate()
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div style={{ textAlign: "center" }}>
          <M3TitleLarge>Error</M3TitleLarge>
          <p>
             Error accessing record
          </p>
            <Button onClick={()=> navigate('/')}>Return to home</Button>
        </div>
      </div>
    );
}
export default ErrorShiftPage