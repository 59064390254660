import React, { useContext, useCallback, useState, useEffect, useMemo } from "react";
import { Box, CircularProgress, FormLabel, Grid, List, Switch } from "@mui/material";
import {
  AddButton,
  FilterButtonMultiple,
} from "../../reusable/Buttons";
import { SearchBox, DatePickerTextField } from "../../reusable/TextField";
import {
  CustomTab,
  CustomTabs,
  LabelDivider,
  PaddingBox,
  SpacedRow,
} from "../../reusable/Scaffolds";
import { ShiftItemList } from "../../reusable/List";
import { get, post, getLocalStorageShiftTab, setLocalStorageShiftTab, removeLocalStorageShiftTab } from "../../../utils/api";
import { Row } from "../../reusable/Scaffolds";
import { checkIfDateIn7Day, debounce } from "../../../utils/helper_functions";
import { useNavigate } from "react-router-dom";
import {
  CANCELLED,
  COMPLETED,
  unreadMessageFilter,
} from "../../../utils/constants";
import { CreateShiftSuccessModal } from "../../reusable/Modal";
import { OrgContext, UserContext } from "../../../utils/context";
import CustomBadge from "../../reusable/BadgeStyles";
import { M3TitleLarge, M3TitleSmall } from "../../reusable/TextStyles";
import { CreateShiftModal } from "./components/create_shift_modal";
import dayjs from "dayjs";
import { compareTwoDates, isValidDate } from "../../../utils/time";
import _ from "lodash";
import theme from "../../../utils/theme";
const filterUnreadConversation = (conversations, current_user_id) => {
  return conversations.filter((conversation) => conversation.conversation.unread_number > 0 && conversation.last_message_user_id !== current_user_id);
}
const tagUnreadMessage = (shifts, current_user_id) => {
  return shifts.map((shift) => {
    const unreadConversation = shift.conversations === null ? [] : filterUnreadConversation(shift.conversations, current_user_id);

    const has_unread = shift.conversations && unreadConversation.length > 0;
    return {
      ...shift,
      has_unread,
    };
  });
}
const Shifts = ({ setAlert }) => {
  const navigate = useNavigate();
  const [shifts, setShifts] = React.useState([]);
  const [unreadShifts, setUnreadShifts] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(1);
  const [selectedCentreLocation, setSelectedCentreLocation] = React.useState(
    []
  );
  const [centreLocations, setCentreLocations] = React.useState([]);
  const [searchingQuery, setSearchingQuery] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [qualifications, setQualifications] = React.useState([]);
  const [openModalCreateShiftSuccess, setOpenModalCreateShiftSuccess] =
    React.useState(false);
  const [createdShifts, setCreatedShifts] = React.useState(null);
  const [centreCreatedShift, setCentreCreatedShift] = React.useState(null);
  const org = useContext(OrgContext)
  const orgName = org ? org.organisation_name : null
  const org_id = org ? org.organisation_id : null
  const tabValues = useMemo(() => [
    { name: "Finished", value: 0 },
    { name: "Open", value: 1 },
    { name: "Filled", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Expired", value: 5 }
  ], []);
  const [filterUnreadMessageStatus, setFilterUnreadMessageStatus] = useState(1)
  const currentUser = useContext(UserContext)
  const [totalShifts, setTotalShifts] = useState({});

  const navigateToDetail = (shift_id) => {
    setLocalStorageShiftTab(currentTab);
    navigate(`/shifts/${shift_id}`);
  };
  const fetchShiftMessage = useCallback(async () => {
    setIsLoading(true);
    const storedTab = getLocalStorageShiftTab()
    let tabValue = currentTab;
    if (storedTab) {
      tabValue = parseInt(storedTab);
      setCurrentTab(tabValue);
      removeLocalStorageShiftTab();
    }
    const { startDate: defaultStartDay, endDate: defaultEndDay } = getCurrentWeekDateRange(tabValue);

    const params = {
      is_open: tabValue === 1,
      is_filled: tabValue === 2,
      is_recent: tabValue === 0,
      is_completed: tabValue === 3,
      is_canceled: tabValue === 4,
      is_expired: tabValue === 5,
      text_query: searchingQuery,
      centre_ids: selectedCentreLocation,
      start_date: startDate || defaultStartDay,
      end_date: endDate || defaultEndDay,
    };

    try{
      const res = await get("/manage/shifts", null, params);
      const taggedShifts = tagUnreadMessage(res.shifts, currentUser.id);
      setShifts(taggedShifts);
      setUnreadShifts(taggedShifts.filter(shift => shift.has_unread))
  
      const totalShifts = await get("/manage/shifts-count-statistic", null, params);
      setTotalShifts(totalShifts);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      setAlert('Failed to fetch shifts');
    }
  }, [searchingQuery, currentTab, selectedCentreLocation, startDate, endDate, currentUser.id, setAlert]);

  const fetchCentres = useCallback(async () => {
    const res = await get(`/manage/organisations/${org_id}/centres`);
    setCentreLocations(res);
  }, [org_id]);

  const fetchQualifications = async () => {
    const res = await get("/manage/qualifications");
    setQualifications(res.qualifications);
  };

  const handlePostShift = async (body) => {
    try {
      const res = await post("/manage/v1/shifts", body);
      setCreatedShifts(res.added_shifts);
      if(!centreLocations.find((element) => element.id.toString() === body.centre_id.toString()))
      {
        /// Sentry log
        console.error("Centre not found");
        console.error("Centre not found", body);
        console.error("Centre not found", centreLocations);
      }
      setCentreCreatedShift(
        centreLocations.find((element) => element.id.toString() === body.centre_id.toString())
      );
      setOpenModal(false);
      setOpenModalCreateShiftSuccess(true);
      fetchShiftMessage();
    } catch (error) {
      setAlert('Shift posted failed');
    }
  };
  const getYesterDayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const dd = String(yesterday.getDate()).padStart(2, "0");
    const mm = yesterday.getMonth() + 1;
    const yyyy = yesterday.getFullYear();
    return `${yyyy}-${mm.toString().padStart(2, '0')}-${dd}`
  };
  function getMinDate() {
    if (currentTab === 0 || currentTab === 3 || currentTab === 4 || currentTab === 5) {
      return null
    }
    const todayDate = getYesterDayDate();
    return todayDate
  };
  function getMaxDate() {
    if (currentTab === 5) {
      return dayjs()
    }

    return null
  };
  const handleTabChange = (event, newValue) => {
    const { endDate } = getCurrentWeekDateRange(currentTab);
    if (newValue === 5) {
      setEndDate(dayjs().format('YYYY-MM-DD'));
    } else {
      setEndDate(endDate)
    }

    setCurrentTab(newValue);
    debounce(setCurrentTab(newValue), 500);
  };
  const onChangeSearch = (searchString) => {
    debounce(setSearchingQuery(searchString), 500);
  };
  const updateDateFilter = (isStartDate, date) => {
    if (date < getMinDate()) {
      return;
    }
    if (!isValidDate(date)) {
      return;
    }

    if (isStartDate) {
      setStartDate(date);
      if (!!endDate && compareTwoDates(date, endDate) === 1) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);
      if (!!startDate && compareTwoDates(startDate, date) === 1) {
        setStartDate(date);
      }
    }

    _.debounce(() => {
      if (isValidDate(date) && isValidDate(isStartDate ? endDate : startDate)) {
      }
    }, 500)();
  };
  const handleSelectedCentresChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCentreLocation(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const getShifts = () => {
    if (filterUnreadMessageStatus === 1) {
      return shifts
    } else {
      return unreadShifts
    }
  }

  function getCurrentWeekDateRange(currentTab) {
    const currentDate = dayjs();
    const startOfWeek = currentDate.startOf('week').add(1, 'day');
    const endOfWeek = startOfWeek.add(4, 'day');
    let startDate =  startOfWeek.format('YYYY-MM-DD');
    const endDate = endOfWeek.format('YYYY-MM-DD');
    if (![0, 3, 4].includes(currentTab)) {
      startDate = currentDate.format('YYYY-MM-DD');
    }
    return { startDate, endDate };
  }

  useEffect(() => {
    fetchCentres();
  }, [fetchCentres]);

  useEffect(() => {
    fetchQualifications();
  }, []);

  useEffect(() => {
    fetchShiftMessage();
  }, [fetchShiftMessage]);

  useEffect(() => {
    if (tabValues.some(tab => tab.value === currentTab) && !startDate && !endDate) {
      const { startDate, endDate } = getCurrentWeekDateRange(currentTab);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [currentTab, startDate, endDate, tabValues ]);
  return (
    <div>
      <CreateShiftSuccessModal
        open={openModalCreateShiftSuccess}
        onClose={() => {
          setOpenModalCreateShiftSuccess(false)
        }}
        data={{
          centre: centreCreatedShift,
          shifts: createdShifts
        }}
      />
      <CreateShiftModal
        open={openModal}
        centres={centreLocations}
        qualifications={qualifications}
        orgId={org_id}
        orgName={orgName}
        onClickCreate={handlePostShift}
        onCancel={() => {
          setOpenModal(false)
        }}
      />
      <SpacedRow>
        <M3TitleLarge>Shifts</M3TitleLarge>
        <AddButton
          text="Create new shift"
          onClick={() => {
            setOpenModal(true)
          }}
        />
      </SpacedRow>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}>
        <CustomTabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="info"
          centered
        >
          <CustomTab
            label={tabValues[0].name}
            icon={
              <CustomBadge
                badgeContent={totalShifts && totalShifts.recentlyCompleted}
              />
            }
            iconPosition="end"
          />
          <CustomTab
            label={tabValues[1].name}
            icon={
              <CustomBadge badgeContent={totalShifts && totalShifts.open} />
            }
            iconPosition="end"
          />
          <CustomTab
            label={tabValues[2].name}
            icon={
              <CustomBadge badgeContent={totalShifts && totalShifts.filled} />
            }
            iconPosition="end"
          />
          <CustomTab
            label={tabValues[3].name}
            icon={
              <CustomBadge
                badgeContent={totalShifts && totalShifts.completed}
              />
            }
            iconPosition="end"
          />
          <CustomTab
            label={tabValues[4].name}
            icon={
              <CustomBadge
                badgeContent={totalShifts && totalShifts.cancelled}
              />
            }
            iconPosition="end"
          />
          <CustomTab
            label={tabValues[5].name}
            icon={
              <CustomBadge
                badgeContent={totalShifts && totalShifts.expired}
              />
            }
            iconPosition="end"
          />
        </CustomTabs>
      </Box>
      <Box sx={{ p: 3 }}>
        <SpacedRow>
          <Row>
            <Row>
              <DatePickerTextField
                label={'Start date'}
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                onChange={(event) => {
                  updateDateFilter(true, event.target.value)
                }}
                value={startDate}
              ></DatePickerTextField>
              <PaddingBox />
              <DatePickerTextField
                label={'End date'}
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                onChange={(event) => {
                  updateDateFilter(false, event.target.value)
                }}
                value={endDate}
              ></DatePickerTextField>
            </Row>
            <FilterButtonMultiple
              currentValue={selectedCentreLocation}
              onChange={handleSelectedCentresChange}
              options={centreLocations}
              placeholder="Centre location"
            ></FilterButtonMultiple>
            <PaddingBox />
            <Switch
              value={filterUnreadMessageStatus === unreadMessageFilter[1].id}
              onChange={(event, value) => {
                setFilterUnreadMessageStatus(
                  value ? unreadMessageFilter[1].id : unreadMessageFilter[0].id
                )
              }}
            />
            <FormLabel>
              <M3TitleSmall>Only unread messages</M3TitleSmall>
            </FormLabel>
          </Row>
          <PaddingBox />
          {currentTab !== 1 ? (
            <SearchBox
              value={searchingQuery}
              onChange={(e) => {
                onChangeSearch(e.target.value)
              }}
              placeholder="Search for an educator"
            ></SearchBox>
          ) : null}
        </SpacedRow>
      </Box>

      {getShifts() && getShifts().length === 0 && !isLoading ? (
        <Grid container justifyContent="center">
          {' '}
          <M3TitleLarge>No shifts found</M3TitleLarge>{' '}
        </Grid>
      ) : null}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {' '}
          <CircularProgress />{' '}
        </Box>
      ) : (
        <List>
          {getShifts() &&
            getShifts().map((shift, index) => (
              <div key={shift.id}>
                {checkIfDateIn7Day(shift.cancel_date) &&
                index === 0 &&
                currentTab === COMPLETED ? (
                  <LabelDivider label={'Last week'} />
                ) : null}
                {checkIfDateIn7Day(shift.cancel_date) &&
                index === 0 &&
                currentTab === CANCELLED ? (
                  <LabelDivider label={'Last week'} />
                ) : null}
                <ShiftItemList
                  onClick={() => {
                    navigateToDetail(shift.id)
                  }}
                  params={{ shift, isOpenTab: currentTab === 1 }}
                ></ShiftItemList>
                {index < getShifts().length - 1 &&
                checkIfDateIn7Day(shift.cancel_date) &&
                !checkIfDateIn7Day(getShifts()[index + 1].shift_date) &&
                currentTab === COMPLETED ? (
                  <LabelDivider label={'Older'} />
                ) : null}
                {index < getShifts().length - 1 &&
                checkIfDateIn7Day(shift.cancel_date) &&
                !checkIfDateIn7Day(getShifts()[index + 1].updated_at) &&
                currentTab === CANCELLED ? (
                  <LabelDivider label={'Older'} />
                ) : null}
              </div>
            ))}
        </List>
      )}
    </div>
  )
};


export default Shifts;
