import DomainIcon from '@mui/icons-material/Domain'
import PlaceIcon from '@mui/icons-material/Place'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { PaddingBox, Row, SpacedRow } from '../../../../reusable/Scaffolds'
import { Divider, List, Skeleton } from '@mui/material'
import { M3LabelMedium } from '../../../../reusable/TextStyles'
import EditIcon from '@mui/icons-material/Edit'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import PersonAddAlt1Outlined from '@mui/icons-material/PersonAddAlt1Outlined';
import { CustomAvatar } from '../../../../Layout'
export const CentreListItem = ({
  centre,
  onEditClick,
  editable,
  openAssignmentGroup
}) => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <SpacedRow>
        <div>
          <Row>
            <DomainIcon /> <PaddingBox />
            {centre.name}
          </Row>
          <Row sx={{ mt: 1 }}>
            <PlaceIcon /> <PaddingBox />
            <M3LabelMedium> {centre.address.address}</M3LabelMedium>
          </Row>
          {centre.images && centre.images?.length > 0 && (
            <Row sx={{ mt: 1 }}>
              <PhotoLibraryIcon /> <PaddingBox />
              {centre.images.map((image) => (
                <CustomAvatar
                  src={image}
                  key={image}
                  sx={{ width: '60px', height: '60px', mr: 1 }}
                >
                  <Skeleton variant="circular" width={60} height={60} />
                </CustomAvatar>
              ))}
            </Row>
          )}
        </div>
        <Row>
          {centre.assignment_group.educator_ids && centre.assignment_group.educator_ids.length ? (
            <GroupAddOutlinedIcon
              sx={{
                cursor: 'pointer'
              }}
              onClick={openAssignmentGroup}
            />
          ) : (
            <PersonAddAlt1Outlined
              sx={{
                cursor: 'pointer',
                color: '#1565c0'
              }}
              onClick={openAssignmentGroup}
            />
          )}
          <PaddingBox />
          {editable ? <EditIcon onClick={onEditClick} /> : null}
        </Row>
      </SpacedRow>
      <PaddingBox />
      <Divider />
      <PaddingBox />
    </div>
  )
}

export const CentreListSkeleton = () => {
  return (
    <List>
      {[0, 1, 2, 3].map((index) => (
        <div key={index} style={{ cursor: 'pointer' }}>
          <SpacedRow>
            <div>
              <Row>
                <DomainIcon /> <PaddingBox />
                <Skeleton width={'50px'}></Skeleton>
              </Row>
              <Row sx={{ mt: 1 }}>
                <PlaceIcon /> <PaddingBox />
                <Skeleton width={'150px'}></Skeleton>
              </Row>
            </div>
          </SpacedRow>
          <PaddingBox />
          <Divider />
          <PaddingBox />
        </div>
      ))}
    </List>
  )
}
