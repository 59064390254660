import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import { M3TitleMedium } from '../../reusable/TextStyles';
import { PaddingBox, Row } from '../../reusable/Scaffolds';
import { RemoveButton } from '../../reusable/Buttons';
import theme from '../../../utils/theme';
import { modalBoxStyles } from '../../reusable/ModalStyles';
const DeleteConfirmationModal = ({ open, onClose, onDelete }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalBoxStyles}>
                <M3TitleMedium>
                    Are you sure you want to delete this policy?
                </M3TitleMedium>
                <PaddingBox />
                <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Button
                        sx={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: 500,
                            color: theme.palette.info.main
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <PaddingBox />
                    <RemoveButton
                        text={'Delete'}
                        onClick={() => {
                            onDelete()
                        }}
                    />
                </Row>
            </Box>
        </Modal>
    )
}
  
export default DeleteConfirmationModal  