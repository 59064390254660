const isSupportStaff = (org) => {
  const { access } = org
  if (!access) throw new Error('No access level found for org (isSupportStaff)')
  return access === 'support'
}

const isManagerOrOwner = (org) => {
  const { access } = org
  if (!access) throw new Error('No access level found for org (isManagerOrOwner)')
  return access === 'manager' || access === 'owner'
}

const isOwner = (org) => {
  if (!org) return false
  const { access } = org
  if (!access) throw new Error('No access level found for org (isOwner)')
  return access === 'owner'
}

module.exports = { isSupportStaff, isManagerOrOwner, isOwner }