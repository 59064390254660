import { Box, Divider, Skeleton, List } from '@mui/material'
import theme from '../../../../utils/theme'
import { SpacedRow } from '../../../reusable/Scaffolds'
import {
  M3BodyMedium,
  M3LabelMedium,
  M3LabelSmall,
  M3TitleMedium,
} from '../../../reusable/TextStyles'
import { formatTimestampToddmmyyyy } from '../../../../utils/time'


export const ApplicationItem = ({ people, isLoading , style, onClick}) => {
  return (
    <Box
      sx={{
        backgroundColor: people.unseen
          ? theme.palette.background.main
          : theme.colors.colorFFFFFF,
        cursor: 'pointer'
      }}
      onClick={onClick}
      style={{ ...style, top: style.top + 8, height: style.height - 12 }}
    >
      <SpacedRow sx={{ padding: 2 }}>
        <div>
          {people.unseen ? (
            <M3LabelSmall>NEW</M3LabelSmall>
          ) : isLoading ? (
            <Skeleton animation="wave" width={40}></Skeleton>
          ) : null}
          <Box sx={{ height: 4 }}></Box>
          {isLoading ? (
            <Skeleton animation="wave" width={100}></Skeleton>
          ) : (
            <M3TitleMedium>
              {people?.first_name} {people?.last_name}{' '}
            </M3TitleMedium>
          )}
          <Box sx={{ height: 4 }}></Box>
          {isLoading ? (
            <Skeleton animation="wave" width={100}></Skeleton>
          ) : (
            <M3BodyMedium>{[people?.qualification.qualification]}</M3BodyMedium>
          )}
        </div>
        {isLoading ? (
          <Skeleton animation="wave" width={100}></Skeleton>
        ) : (
          <M3LabelMedium>{`Applied on ${formatTimestampToddmmyyyy(
            people?.created_at
          )}`}</M3LabelMedium>
        )}
      </SpacedRow>
      {!people?.unseen ? <Divider /> : null}
    </Box>
  )
}

export const ApplicationSkeleton = () => {
    return (
        <List>
            {[1,2,3,4,5].map((index) => {
                return (
                  <SpacedRow key={index} sx={{ padding: 2 }}>
                    <div>
                      <Skeleton animation="wave" width={40}></Skeleton>
                      <Box sx={{ height: 4 }}></Box>
                      <Skeleton animation="wave" width={100}></Skeleton>
                      <Box sx={{ height: 4 }}></Box>
                      <Skeleton animation="wave" width={100}></Skeleton>
                    </div>
                    <Skeleton animation="wave" width={100}></Skeleton>
                  </SpacedRow>
                )
            })}
        </List>
    )
    }