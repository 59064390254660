import { Container, List } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { get } from '../../../../utils/api'
import DeactivateLoginItem, { SkeletonLoginItemList } from './components/deactivated_user_item'
import { UserContext } from '../../../../utils/context'
export default function DeactiveTab({ org_id, onItemClick, update}) {
  const [userList, setUserList] = useState([])
  const currentUser = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const fetchDeactiveListStaff = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await get(`/manage/organisations/${org_id}/staff?status=deactivated`)
      const userListRes = res.filter((user) => user.id !== currentUser.id)
      setUserList(userListRes)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }, [org_id, currentUser])

  useEffect(() => {
    fetchDeactiveListStaff()
  }, [fetchDeactiveListStaff, update])

  return (<List>
    {isLoading ? (
        <SkeletonLoginItemList key={0}/>
      ) : userList.length === 0 ? (
        <Container>
          <p>No logins found</p>
        </Container>
      ) :userList && userList.map((user) => (
      <div key={user.id}>
        <DeactivateLoginItem onClick={() =>{
          onItemClick(user)
        } } params={{ user }} />
      </div>
    ))}
  </List>)
}
