import React, { useMemo, useRef, useState, useEffect } from 'react';
import { TextField, Box, Button, Grid, Typography, Container } from '@mui/material';
import { PaddingBox } from '../../../reusable/Scaffolds';
import { CommonTextField } from '../../../reusable/TextField';
import { isValidPhoneNumber } from "../../../../utils/helper_functions";

const OtpInput = ({ value, onChange, handleOtpComplete, disabled }) => {
  const inputRefs = useRef([]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').trim();
    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split('');
      onChange(pastedData);
      handleOtpComplete(pastedData);
      
      // Distribute digits across TextFields
      newOtp.forEach((digit, index) => {
        inputRefs.current[index].value = digit;
      });
      
      inputRefs.current[5].focus(); // Focus on the last input
    }
  };

  const handleChange = (index, e) => {
    const digit = e.target.value;
    if (digit.length <= 1 && /^[0-9]*$/.test(digit)) {
      const newOtp = value.split('');
      newOtp[index] = digit;
      const newOtpString = newOtp.join('');
      onChange(newOtpString);
      if (digit !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
      if (newOtpString.length === 6) {
        handleOtpComplete(newOtpString);
      }
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && value[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  }

  return (
    <Box display="flex" justifyContent="space-between">
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <TextField
          key={index}
          inputRef={(el) => (inputRefs.current[index] = el)}
          type="text"
          onPaste={handlePaste}
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          style={{
            width: '65px',
            fontSize: '16px',
            textAlign: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
          InputProps={{
            style: { height: '100%' }
          }}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

function OneTimePasswordLogin({ handleSendOtp, handleOtpComplete, updateError, currentError }) {
  const [identifier, setIdentifier] = useState('');
  const [otp, setOtp] = useState('');
  const [isValidInput, setIsValidInput] = useState(false);
  const [authenticationType, setAuthenticationType] = useState('');
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem('otpTimer');
    const savedTimestamp = localStorage.getItem('otpTimestamp');
    if (savedTimer && savedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
      const remainingTime = Math.max(0, parseInt(savedTimer) - elapsedTime);
      return remainingTime;
    }
    return 0;
  });

  const [otpSent, setOtpSent] = useState(() => {
    return localStorage.getItem('otpSent') === 'true';
  });
  
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  const isOtpInputDisabled = useMemo(() => {
    return currentError === 'Attempt 5 / 5: failed';
  }, [currentError]);

  const clearStorage = () => {
    localStorage.removeItem('otpTimer');
    localStorage.removeItem('otpTimestamp');
    localStorage.setItem('otpSent', 'false');
  }

  const getCode = () => {
    setOtp('');
    setOtpSent(true);
    setTimer(120);
    localStorage.setItem('otpTimer', '120');
    localStorage.setItem('otpTimestamp', Date.now().toString());
    localStorage.setItem('otpSent', 'true');
  };

  useEffect(() => {
    if (timer > 0) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          localStorage.setItem('otpTimer', newTimer.toString());
          localStorage.setItem('otpTimestamp', Date.now().toString());
          if (newTimer <= 0) {
            clearInterval(timerRef.current);
            setOtpSent(false);
            clearStorage()
          }
          return newTimer;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [timer]);

  const onChangeEmailOrPhone = (event) => {
    const input = event.target.value.replace(/\s/g, '');
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    const isValidPhone = isValidPhoneNumber(input)
    setIsValidInput(isValidEmail || isValidPhone);
    setIdentifier(input);
    updateError('');
    
    if (isValidEmail) {
      setAuthenticationType('email');
    } else if (isValidPhone) {
      setAuthenticationType('sms');
    }
  };
  function removeCountryCode(phoneNumber) {
    if(/^\+614\d{8}$/.test(phoneNumber)) {
      return phoneNumber.replace(/^\+614/, '04');
    }
    else if(/^\+615\d{8}$/.test(phoneNumber)) {
      return phoneNumber.replace(/^\+615/, '05');
    }
    return phoneNumber;
  }
  const requestOtpCode = async () => {
    try {
      const otpResult = await handleSendOtp(
        /^\+61(4|5)\d{8}$/.test(identifier)
          ? removeCountryCode(identifier)
          : identifier,
        authenticationType
      )
      if(otpResult === 'success') {
        getCode();
      }
    } catch (err) {
      updateError(err.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isValidInput) {
      requestOtpCode();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <PaddingBox />
      <CommonTextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Email Address or Mobile number"
        type="text"
        value={identifier}
        onChange={onChangeEmailOrPhone}
        onKeyDown={handleKeyPress}
        error={identifier !== '' && !isValidInput}
      />
      <PaddingBox />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <OtpInput 
            value={otp} 
            onChange={setOtp} 
            handleOtpComplete={(completedOtp) => {
              handleOtpComplete(identifier, completedOtp, authenticationType);
              clearStorage()
            }}
            disabled={isOtpInputDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          {otpSent ? (
            <Box sx={{ 
              backgroundColor: '#e4dfc4', 
              padding: '12px 16px 8px 16px', 
              borderRadius: '6px', 
              display: 'inline-block'
            }}>
              <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', color: '#333', fontSize: '22px' }}>
                {Math.floor(timer / 60).toString().padStart(2, '0')} : {(timer % 60).toString().padStart(2, '0')}
              </Typography>
            </Box>
          ) : (
            <Button 
              onClick={requestOtpCode}
              fullWidth 
              variant="contained" 
              color="primary"
              disabled={!isValidInput}
              style={{ textTransform: 'none', fontWeight: 'bold', width: '105px' }}
            >
              GET CODE
            </Button>
          )}
        </Grid>
      </Grid>
      {otpSent && <Typography variant="body2" style={{ marginTop: '10px' }}>Your code was sent via {authenticationType === 'email' ? 'email' : 'SMS'}</Typography>}
      <PaddingBox />
    </Container>
  );
}

export default OneTimePasswordLogin;