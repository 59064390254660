const {
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Skeleton
} = require('@mui/material')
const { M3LabelSmall } = require('../../../../reusable/TextStyles')

export default function DeactivateLoginItem({ params, onClick }) {
  const { user } = params
  const userName = user.first_name + ' ' + user.last_name
  const userRole = user.access.charAt(0).toUpperCase() + user.access.slice(1)
  return (
    <ListItemButton onClick={onClick}>
      <ListItemText primary={userName} secondary={userRole} />
      <ListItemSecondaryAction>
        <M3LabelSmall>
         {user.email}
        </M3LabelSmall>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}

export function SkeletonLoginItemList() {
  return (
    <div>
      {[0, 1, 2, 3].map((index) => (
        <ListItemButton key={index}>
          <ListItemText
            primary={<Skeleton variant="text" width={200} />}
            secondary={<Skeleton variant="text" width={100} />}
          />
        </ListItemButton>
      ))}
    </div>
  )
}
