import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CloseButton } from "../reusable/Buttons";

const ShiftForm = ({
  type,
  initialData,
  onClose,
  onSave,
  centreList,
  defaultCentreId,
}) => {
  const [formData, setFormData] = useState(
    initialData || {
      centre_id: defaultCentreId || "",
      active: initialData?.active ?? true,
      is_public: initialData?.is_public ?? true,
      shift_date: initialData?.shift_date || "",
      shift_start: initialData?.shift_start || "",
      shift_end: initialData?.shift_end || "",
      bonus: initialData?.bonus || 0,
      description: initialData?.description || "",
    }
  );

  useEffect(() => {
    if (initialData) {
      setFormData({
        centre_id: defaultCentreId || "",
        active: initialData?.active ?? true,
        is_public: initialData?.is_public ?? true,
        shift_date: initialData?.shift_date || "",
        shift_start: initialData?.shift_start || "",
        shift_end: initialData?.shift_end || "",
        bonus: initialData?.bonus || 0,
        description: initialData?.description || "",
        ...initialData,
      });
    }
  }, [initialData, defaultCentreId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{type === "add" ? "Add Shift" : "Update Shift"}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl fullWidth margin="normal">
            <InputLabel id="centre_id-label">Centre</InputLabel>
            <Select
              name="centre_id"
              value={formData.centre_id}
              onChange={handleChange}
            >
              {centreList.map((centre) => (
                <MenuItem key={centre.id} value={centre.id}>
                  {centre.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Shift Date"
            type="date"
            name="shift_date"
            value={formData.shift_date}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Shift Start"
            type="time"
            name="shift_start"
            value={formData.shift_start}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Shift End"
            type="time"
            name="shift_end"
            value={formData.shift_end}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Bonus"
            type="number"
            name="bonus"
            value={formData.bonus}
            onChange={handleChange}
            InputProps={{ startAdornment: "$" }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.active}
                onChange={handleCheckboxChange}
                name="active"
              />
            }
            label="Active"
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.is_public}
                onChange={handleCheckboxChange}
                name="is_public"
              />
            }
            label="Public"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={onClose} text={"Cancel"} />
        <Button color="primary" onClick={handleSubmit}>
          {type === "add" ? "Add" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShiftForm;
