import React, { useEffect, useState } from 'react';
import {  Button, Alert, Container, Paper, Typography, Box } from '@mui/material';
import { get, post, setLocalStorageToken } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { usePlacesWidget } from "react-google-autocomplete";
import { M3BodyLarge, M3BodyMedium } from '../reusable/TextStyles';
import { PaddingBox, PaddingBoxSmall } from '../reusable/Scaffolds';
import { CommonTextField } from '../reusable/TextField';

const SignupForm = ({ secret }) => {
  const [password, setPassword] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [orgInvite, setOrgInvite] = useState(false); 
  const [userInfo, setUserInfo] = useState({
    address: '',
    email: '',
    first_name: '',
    last_name: '',
    deactivated: false,
  }); // For display only
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || ""
  const [formErrors, setFormErrors] = useState({
    password: '',
  });
  const isValidPassword = (password) => {
    // Password must contain uppercase and lowercase letters, and be at least 8 characters long
    const re = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  }
  const onPasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    if (!isValidPassword(password)) {
      setFormErrors((prev) => {
        return {
          ...prev,
          password:
            "Password must contain uppercase and lowercase letters, and be at least 8 characters long",
        };
      });
    } else {
      setFormErrors((prev) => {
        return { ...prev, password: "" };
      });
    }
    
  }
  const { ref } = usePlacesWidget({
    apiKey,
    onPlaceSelected: (place) => {
      const location = place.geometry.location;
      setLat(location.lat());
      setLng(location.lng());
      setUserInfo((prev) => {
        return { ...prev, address: place.formatted_address }
      });
    },
    inputAutocompleteValue: "country",
    options: {
      types: ['address'],
      componentRestrictions: { country: "au" },
    },
  });
  const isValidAddress = (user) => {
    if (user.address) {
      return lat !== 0 && lng !== 0
    }
    return true
  }
  useEffect(() => {
    const getUserInfoByInviteSecret = async () => {
      try {
        const response = await get(`/invite/${secret}`);
        if (response.organisations && response.organisations.length > 0) {
          response.organisations.forEach((org) => {
            if (org.access !== 'educator') {
              setOrgInvite(true);
            }
          });
        }
        setUserInfo({
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          address: response.address.address ?? "",
          deactivated: response.deactivated,
        })
        setLat(response.address.lat);
        setLng(response.address.lng);        
      } catch (err) {
        console.error("Failed to fetch user info:", err);
      }
    };

    if (secret) {
      getUserInfoByInviteSecret();
    }
  }, [secret]);

  const handleSignup = async () => {
    setLoading(true);
    setError(null);

    const payload = { password, first_name: userInfo.first_name, last_name: userInfo.last_name, address: userInfo.address, lat, lng, invite_secret: secret };

    try {
      const { token } = await post('/signup', payload);
      if (!token) throw new Error('Signup failed');
      setLocalStorageToken(token);
      navigate( `/signup-success/${ orgInvite ? 'true' : 'false'}` );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component="h1" variant="h5">
          Signup
        </Typography>
        <form style={{ width: '100%', marginTop: '8px' }}>
          <M3BodyLarge>Invited Email: {userInfo.email}</M3BodyLarge>
          <PaddingBoxSmall />
          <input type="hidden" name="invite_secret" value={secret} />
          <CommonTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="First Name"
            value={userInfo.first_name}
            onChange={(e) =>
              setUserInfo((prev) => {
                return { ...prev, first_name: e.target.value }
              })
            }
            autoFocus
          />
          <PaddingBoxSmall />
          <CommonTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Last Name"
            value={userInfo.last_name}
            onChange={(e) =>
              setUserInfo((prev) => {
                return { ...prev, last_name: e.target.value }
              })
            }
          />
          <PaddingBoxSmall />
          {
            !orgInvite &&
            <Box>
              {' '}
              <CommonTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="text"
                label="Home Address"
                value={userInfo.address}
                onChange={(e) => {
                    setLat(0);
                    setLng(0);
                    setUserInfo((prev) => {
                      return { ...prev, address: e.target.value}
                    })
                  }
                }
                inputRef={ref}
              />
              <PaddingBoxSmall />
            </Box>
          }
          {isValidAddress(userInfo) ? null : (
            <Typography color="error">Please select a valid address</Typography>
          )}
          <CommonTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={formErrors.password}
            label="Password"
            type="password"
            value={password}
            onChange={(e) => onPasswordChange(e)}
          />
          <M3BodyMedium>
            Password must contain uppercase and lowercase letters, and be at
            least 8 characters long
          </M3BodyMedium>
          <PaddingBox />
          <Button
            onClick={handleSignup}
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || userInfo.deactivated || !isValidAddress(userInfo) || !isValidPassword(password)}
          >
            Signup
          </Button>
          {userInfo.deactivated && (
            <Alert severity="error" style={{ marginTop: '10px' }}>
              Invite has been cancelled
            </Alert>
          )}
          {error && (
            <Alert severity="error" style={{ marginTop: '10px' }}>
              {error}
            </Alert>
          )}
        </form>
      </Paper>
    </Container>
  )
};

export default SignupForm;
