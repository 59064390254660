import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from '../../utils/api';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Fab, Tabs, Tab, Link, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import OrgUserForm from '../forms/OrgUserForm';
import Loading from '../reusable/Loading';

const OrganisationDetail = () => {
  const navigate = useNavigate();
  const { org_id } = useParams();
  const [org, setOrg] = useState(null);
  const [orgUsers, setOrgUsers] = useState(null);
  const [orgCentres, setOrgCentres] = useState(null);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userError, setUserError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Needs to be a new page here instead of modal since Google Maps won't work otherwise
  const navigateToAddCentre = () => {
    navigate(`/organisations/${org_id}/add-centre`);
  };

  const fetchData = async () => {
    try {
      const orgData = await get(`/manage/organisations/${org_id}`);
      setOrg(orgData);
      const centreData = await get(`/manage/organisations/${org_id}/centres`);
      setOrgCentres(centreData)
      const userData = await get(`/manage/organisations/${org_id}/people`);
      setOrgUsers(userData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [org_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveUser = (email, access, selectedCentres) => {
    post(`/manage/organisations/${org_id}/people`, { email, access, centres: selectedCentres })
      .then(() => {
        handleCloseUserModal();
        setUserError(null);
        setUserToEdit(null);
        fetchData();
      })
      .catch((error) => {
        setUserError("Error adding user, check if they already exist");
        console.error(error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!org) {
    return <Loading />;
  }

  const handleAddUserClick = () => {
    setUserToEdit(null);
    setUserError(null);
    setOpenUserModal(true);
  }

  const handleEditUserClick = (user) => {
    setUserToEdit(user);
    setUserError(null);
    setOpenUserModal(true);
  };

  const handleCloseUserModal = () => {
    setOpenUserModal(false);
    setUserToEdit(null); // Add this line to reset the user to edit
  };

  const centreAccessText = (centres) => {
    if (centres.length === 0) {
      return '';
    } else if (centres.length === 1) {
      return '/ 1 centre';
    } else {
      return `/ ${centres.length} centres`;
    }
  }

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Typography variant="h4">{org.name}</Typography>
      <Typography variant="body1">{org.description}</Typography>
      <Typography variant="body2">Email: <Link href={`mailto:${org.email}`}>{org.email}</Link></Typography>
      <Typography variant="body2">ABN: <Link href={`https://abr.business.gov.au/ABN/View?abn=${org.abn}`} target="_blank" rel="noopener noreferrer">{org.abn}</Link></Typography>

      <Tabs value={tabValue} onChange={handleTabChange} style={{ marginTop: '20px' }}>
        <Tab label="Centres" />
        <Tab label="Users" />
      </Tabs>

      {tabValue === 0 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">{org.name} Centres</Typography>
            <Fab color="primary" aria-label="add" onClick={navigateToAddCentre}>
              <AddIcon />
            </Fab>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgCentres && orgCentres.map((centre) => (
                <TableRow key={centre.id}>
                  <TableCell><Link href={`/organisations/${centre.organisation_id}/centres/${centre.id}`}>{ centre.name }</Link></TableCell>
                  <TableCell><Link href={`https://www.google.com/maps/search/?api=1&query=${centre.address.lat},${centre.address.lng}`} target="_blank" rel="noopener noreferrer">{centre.address.address}</Link></TableCell>
                  <TableCell>
                    <RouterLink to={`/organisations/${centre.organisation_id}/centres/${centre.id}/edit`}>
                      <EditIcon />
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

      {tabValue === 1 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">{org.name} Users</Typography>
            <Fab color="primary" aria-label="add" onClick={() => handleAddUserClick()}>
              <AddIcon />
            </Fab>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Access</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgUsers && orgUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{ user.first_name ? `${user.first_name} ${user.last_name}` : 'n/a'}</TableCell>
                  <TableCell><Link href={`mailto:${user.email}`}>{user.email}</Link></TableCell>
                  <TableCell style={{ color: user.password_hash ? 'green' : 'red' }}>
                      { user.password_hash ? 'Accepted' : 'Pending' }
                  </TableCell>
                  <TableCell>{ user.access } {centreAccessText(user.centres)}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleEditUserClick(user)} size="small">
                      <EditIcon />
                    </Button>
                  </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <OrgUserForm
            open={openUserModal}
            setOpen={setOpenUserModal}
            handleSaveUser={handleSaveUser}
            orgCentres={orgCentres}
            user={userToEdit}
            isEditing={userToEdit !== null}
            error={userError} />
      </>
      )}
    </Paper>
  );
};

export default OrganisationDetail;
