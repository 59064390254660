import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FilteredSelectionComponent(
  { options, onChange, inputValue }
) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      value={inputValue}
      defaultValue={[]}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            sx={{ color: 'black' }}
            {...getTagProps({ index })}
            disabled={true}
          />
        ))
      }
      onChange={onChange}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Organisation educators" placeholder="Select your educators" />
      )}
    />
  );
}
