import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Button, TextField, FormControl } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { usePlacesWidget } from "react-google-autocomplete";
import { get, post, postFile } from '../../utils/api';

const OrgCentreForm = () => {
  const navigate = useNavigate();
  const { org_id, centre_id } = useParams();
  const [imageFiles, setImageFiles] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    lat: '',
    lng: '',
    description: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    images: [],
  });

  useEffect(() => {
    if (centre_id) {
      get(`/manage/organisations/${org_id}/centres/${centre_id}`)
        .then((data) => {
          data.lat = data.address.lat
          data.lng = data.address.lng
          data.address = data.address.address
          if (!data.images) data.images = [];
          setFormData({
            ...formData,
            ...data
          });
        })
        .catch(console.error);
    }
  }, [centre_id, org_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || "AIzaSyARpjmEVivWJjwITUGeeP55aHjz97M1L7Q";

  const { ref } = usePlacesWidget({
    apiKey,
    onPlaceSelected: (place) => {
      const location = place.geometry.location;
      setFormData({
        ...formData,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng()
      });
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: "au" },
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveCentre = () => {
    post(`/manage/organisations/${org_id}/centres${centre_id ? `/${centre_id}` : ''}`, formData)
      .then(() => navigate(`/organisations/${org_id}`))
      .catch(console.error);
  };

  const handleImageFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const newImageFiles = [...imageFiles, ...files];
    setImageFiles(newImageFiles);

    for (const file of files) {
      try {
        const response = await postFile('/upload', file);
        if (response.url) {
          setFormData(prevState => ({
            ...prevState,
            images: [...prevState.images, response.url]
          }));
        }
      } catch (error) {
        console.error('Failed to upload image.');
      }
    }
  };

  const handleRemoveImage = (urlToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      images: prevState.images.filter(url => url !== urlToRemove)
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">{centre_id ? 'Edit Centre' : 'Add Centre'}</Typography>
        <FormControl fullWidth>
          {Object.keys(formData).map((key) => {
            if (key === 'id' || key === 'organisation_id' || key === 'images') return null; // Skip these fields
            return (
              <TextField
                key={key}
                variant="outlined"
                margin="normal"
                fullWidth
                label={key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                inputRef={key === 'address' ? ref : null}
                style={key === 'lat' || key === 'lng' ? { display: 'none' } : null }
              />
            );
          })}
        </FormControl>
        <input type="hidden" name="lat" value={formData.lat} />
        <input type="hidden" name="lng" value={formData.lng} />
        <Button variant="contained" component="label">
          Select Centre Images
          <input type="file" hidden multiple onChange={handleImageFileChange} />
        </Button>
        {(formData.images || []).map((url, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img src={url} alt={`Selected ${index}`} style={{ width: '100px', height: 'auto' }} />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: '10px' }}
              onClick={() => handleRemoveImage(url)}
            >
              Remove
            </Button>
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleSaveCentre}>{centre_id ? 'Update' : 'Create'}</Button>
          <Button variant="text" color="primary" onClick={() => navigate(`/organisations/${org_id}`)}>Cancel</Button>
        </div>
      </Paper>
    </Container>
  );
};

export default OrgCentreForm;
