import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Typography, Fab, Link, Table, TableBody, TableHead, TableRow, TableCell, Modal, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ShiftForm from '../../forms/ShiftForm';
import { get, post, put } from '../../../utils/api';
import { formatDate, formatTime } from '../../../utils/time';

const CentreDetail = () => {
  const { org_id, centre_id } = useParams();
  const [centre, setCentre] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [centreList, setCentreList] = useState(null);
  const [openShiftModal, setOpenShiftModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [modalType, setModalType] = useState('add'); // 'add' or 'edit'

  const fetchData = async () => {
    try {
      const centreData = await get(`/manage/organisations/${org_id}/centres/${centre_id}`);
      setCentre(centreData);
      const shiftData = await get(`/manage/organisations/${org_id}/centres/${centre_id}/shifts`);
      setShifts(shiftData);
      const centreListData = await get(`/manage/organisations/${org_id}/centres`);
      setCentreList(centreListData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [org_id, centre_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddOrEditShift = async (shiftDetails) => {
    try {
      if (modalType === 'add') {
        await post(`/manage/organisations/${org_id}/centres/${centre_id}/shifts`, shiftDetails);
      } else {
        await put(`/manage/organisations/${org_id}/centres/${centre_id}/shifts`, shiftDetails);
      }
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddShift = () => {
    setModalType('add');
    setSelectedShift({ centre_id });
    setOpenShiftModal(true);
  };

  const handleEditShift = (shift) => {
    setModalType('edit');
    const initialData = shift;
    setSelectedShift(initialData);
    setOpenShiftModal(true);
  };

  if (!centre) {
    return <div>Loading...</div>;
  }

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Typography variant="h4">{centre.name}</Typography>
      <Typography variant="body1">
        <Link href={`https://www.google.com/maps/search/?api=1&query=${centre.address.lat},${centre.address.lng}`} target="_blank" rel="noopener noreferrer">{centre.address.address}
        </Link>
      </Typography>

      <br/>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Open Shifts</Typography>
        <Fab color="primary" aria-label="add" onClick={handleAddShift}>
          <AddIcon />
        </Fab>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shifts && shifts.map((shift) => (
            <TableRow key={shift.id}>
              <TableCell>{shift.id}</TableCell>
              <TableCell>
                {formatDate(shift.shift_date)}<br/>
                {formatTime(shift.shift_start)} -&nbsp;
                {formatTime(shift.shift_end)}
              </TableCell>
              <TableCell>{shift.active ? "Active" : "Inactive"}</TableCell>
              <TableCell>
                <Button variant="outlined" color="primary" onClick={() => handleEditShift(shift)}>
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openShiftModal && (
        <Modal open={openShiftModal} onClose={() => setOpenShiftModal(false)}>
          <Box sx={{ outline: 'none', p: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper' }}>
            <ShiftForm
              type={modalType}
              initialData={selectedShift}
              onClose={() => setOpenShiftModal(false)}
              onSave={handleAddOrEditShift}
              centreList={centreList}
              defaultCentreId={centre_id}
            />
          </Box>
        </Modal>
      )}
    </Paper>
  );
};

export default CentreDetail;
