import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Modal,
  FormControl,
  TextField
} from '@mui/material'
import {
  M3BodyMedium,
  M3TitleLarge,
} from '../../../../reusable/TextStyles'
import EditIcon from '@mui/icons-material/Edit'

import { PaddingBox, Row, SpacedRow } from '../../../../reusable/Scaffolds'
import { CloseButton, PrimaryButton, RemoveButton } from '../../../../reusable/Buttons'
import { modalBoxStyles } from '../../../../reusable/ModalStyles'
import theme from '../../../../../utils/theme'
const PolicyDetailModal = ({
  open,
  onClose,
  onSave,
  selectedPolicy,
  onDelete,
  isAddModal,
  editable,
}) => {
  const [isEditting, setIsEditting] = useState(isAddModal)
  const [policy, setPolicy] = useState(selectedPolicy)
  const [changed, setChanged] = useState(false)
  const checkForChanges = (name, content) => {
    if (
      content !== '' &&
      name !== '' &&
      (name !== selectedPolicy.name || content !== selectedPolicy.content) &&
      content &&
      name
    ) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }
  useEffect(() => {
    setPolicy(selectedPolicy)
  }, [selectedPolicy])
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={modalBoxStyles}
        style={{
          maxHeight: '80vh'
        }}
      >
        {isEditting ? (
          <FormControl fullWidth>
            <TextField
              label="Policy name"
              value={policy.name}
              onChange={(event) => {
                setPolicy({ ...policy, name: event.target.value })
                checkForChanges(event.target.value, policy.content)
              }}
            />
          </FormControl>
        ) : (
          <SpacedRow>
            <M3TitleLarge
              onClick={() => {
                setIsEditting(true)
              }}
              style={{
                cursor: 'pointer'
              }}
            >
              {policy?.name}
            </M3TitleLarge>
            {editable ? (
              <EditIcon
                sx={{
                  color: theme.palette.primary.main
                }}
                onClick={() => {
                  setIsEditting(true)
                }}
              />
            ) : null}
          </SpacedRow>
        )}
        <PaddingBox />
        {isEditting ? (
          <FormControl fullWidth>
            <TextField
              label="Content"
              value={policy?.content}
              multiline
              rows={8}
              onChange={(event) => {
                setPolicy({ ...policy, content: event.target.value })
                checkForChanges(policy.name, event.target.value)
              }}
            />
          </FormControl>
        ) : (
          <Box
            style={{
              maxHeight: '60vh',
              overflow: 'auto'
            }}
          >
            <M3BodyMedium
              onClick={() => {
                setIsEditting(true)
              }}
              style={{
                cursor: 'pointer'
              }}
            >
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {' '}
                {policy?.content}{' '}
              </Typography>
            </M3BodyMedium>
          </Box>
        )}
        <PaddingBox />
        <Row style={{ flex: 1, justifyContent: 'space-between' }}>
          <CloseButton
            text={'Cancel'}
            onClick={() => {
              setIsEditting(isAddModal)
              setChanged(false)
              onClose()
            }}
          />
          <Row>
            {isAddModal || !editable ? null : (
              <RemoveButton
                text={'Delete'}
                onClick={() => {
                  setIsEditting(isAddModal)
                  setChanged(false)
                  onDelete()
                }}
              />
            )}
            <PaddingBox />
            {isEditting ? (
              <PrimaryButton
                text={'Save'}
                isDisabled={!changed}
                onClick={() => {
                  setIsEditting(isAddModal)
                  onSave(policy)
                }}
              />
            ) : null}
          </Row>
        </Row>
      </Box>
    </Modal>
  )
}

export default PolicyDetailModal
