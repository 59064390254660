import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Chip, Alert } from '@mui/material';
import { CloseButton } from '../reusable/Buttons';

const OrgUserForm = ({ open, setOpen, handleSaveUser, orgCentres, user, isEditing, error }) => {
  const [email, setEmail] = useState('');
  const [access, setAccess] = useState('');
  const [selectedCentres, setSelectedCentres] = useState(user ? user.centres : []);

  useEffect(() => {
    if (isEditing) {
      setEmail(user.email);
      setAccess(user.access);
      setSelectedCentres(user.centres);
    } else {
      resetForm();
    }
  }, [user, isEditing]);

  const handleSubmit = () => {
    handleSaveUser(email, access, selectedCentres)

    if (!error) {
      resetForm();
    }
  };

  const resetForm = () => {
    setEmail('');
    setAccess('');
    setSelectedCentres([]);
  };

  const handleCentreChange = (event) => {
    setSelectedCentres(event.target.value);
  };

  const handleDialogClose = () => {
    setOpen(false);
    resetForm(); // Reset the form when the dialog is closed
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditing ? 'Edit' : 'Invite'} User</DialogTitle>
      <DialogContent style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
        <TextField label="Email" fullWidth value={email} onChange={(e) => !isEditing ? setEmail(e.target.value) : null } />
        <FormControl fullWidth>
          <InputLabel id="access-label">Access</InputLabel>
          <Select value={access} onChange={(e) => setAccess(e.target.value)}>
            <MenuItem value="owner">Owner</MenuItem>
            <MenuItem value="manager">Manager</MenuItem>
            <MenuItem value="support">Support</MenuItem>
            <MenuItem value="educator">Educator</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="centres-label">Centres</InputLabel>
          <Select
            labelId="centres-label"
            multiple
            value={selectedCentres}
            onChange={handleCentreChange}
            input={<OutlinedInput id="select-multiple-chip" label="Centres" />}
            renderValue={(selected) => (
              <div>
                {selected
                  .filter(value => orgCentres.some(centre => centre.id === value))
                  .map((value) => {
                    const foundCentre = orgCentres.find(centre => centre.id === value);
                    return <Chip key={value} label={foundCentre.name} />;
                  })
                }
              </div>
            )}
          >
            {orgCentres.map((centre) => (
              <MenuItem key={centre.id} value={centre.id}>
                {centre.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={handleDialogClose} text={'Cancel'} />
        <Button onClick={handleSubmit} color="primary">
          {isEditing ? 'Save' : 'Invite'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgUserForm;
