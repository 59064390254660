import {
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
  MenuItem,
  Grid
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApprovalCheckbox,
  BackButton,
  DropDownButton,
  PrimaryButton,
  RemoveButton,
  SuccessButton,
} from "../reusable/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomTab,
  CustomTabs,
  PaddingBox,
  Row,
  SpacedRow,
} from "../reusable/Scaffolds";
import { Tags } from "../reusable/Tags";
import { debounce, getQualificationDateLabel } from "../../utils/helper_functions";
import {
  CommonTextField,
  DatePickerTextField
} from "../reusable/TextField";
import { EducatorShiftItemList } from "../reusable/List";
import { get, post } from "../../utils/api";
import { M3BodyMedium, M3TitleLarge, M3TitleMedium } from "../reusable/TextStyles";
import { compareTwoDates, isValidDate } from "../../utils/time";
import _ from "lodash";
import theme from "../../utils/theme";
import { CustomAvatar } from "../Layout";
import { PercentCompleteItem, PreviousQualificationItem, EctLevelItem } from "../reusable/QualificationItem";
import { qualificationLevelId } from "../../utils/constants";
const quickcareEducator = 7;
const EducatorDetail = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [educatorInfo, setEducatorInfo] = React.useState(null);
  const [shifts, setShifts] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const [qualificationsList, setQualificationsList] = useState([]);

  const getTodayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  function getMaxDate() {
    if (currentTab === 1 || currentTab === 2) {
      return null;
    }
    return getTodayDate();
  }

  function getMinDate() {
    if (currentTab === 0 || currentTab === 3 || currentTab === 4) {
      return null;
    }
    const todayDate = getTodayDate();
    return todayDate;
  }

  const navigateToShiftDetail = (shift_id) => {
    navigate(`/shifts/${shift_id}?from=educator_detail`);
  };

  const handleTabChange = (event, newValue) => {
    debounce(setCurrentTab(newValue), 500);
  };

  function decideTagType(educatorInfo) {
    if (educatorInfo && educatorInfo.available) {
      return 6;
    } else return 5;
  }

  const tabValues = [
    { name: "Assigned shifts", value: 0 },
    { name: "Profile", value: 1 },
  ];

  const fetchShift = useCallback(async () => {
    if (!id || (startDate.toString().includes('Invalid') || endDate.toString().includes('Invalid'))) return;
    setIsLoading(true);
    try {
      const res = await get(`/admin/educator/${id}/shifts`,null, {
        start_date: startDate,
        end_date: endDate,
      });
      setShifts(res);
    } catch (e) {}
    setIsLoading(false);
  }, [id, startDate, endDate]);

  const fetchQualifications = async () => {
    try {
      const orgData = await get('/manage/qualifications', null, {
        is_profile: true
      })
      const qualificationMapped = orgData.qualifications.filter(item => item.id !== qualificationLevelId.ANY).map(
        (qualification) => {
          qualification.value = qualification.id
          qualification.name = qualification.qualification
          return qualification
        }
      )
      setQualificationsList(qualificationMapped)
    } catch (error) {
      console.error(error)
    }
  };

  const deactivateAnEducator = async (userId) => {
    try {
      await post(`/admin/educator/${userId}/deactivate`)
      setEducatorInfo((prevInfo) => ({
        ...prevInfo,
        deactivated: true
      }))
    } catch (error) {
      console.error(error)
    }
  };

  const reActivateAnEducator = async (userId) => {
    try {
      await post(`/admin/educator/${userId}/reactivate`)
      setEducatorInfo((prevInfo) => ({
        ...prevInfo,
        deactivated: false
      }))
    } catch (error) {
      console.error(error)
    }
  };

  const updateDateFilter = (isStartDate, date) => {
    if (isStartDate &&  (!!endDate && compareTwoDates(date, endDate) === 1)) {
      return
    }
    if (!isStartDate && (!!startDate && compareTwoDates(startDate, date) === 1)) {
      return
    }
    _.debounce(() => {
      if (isValidDate(date)) {
        isStartDate ? setStartDate(date) : setEndDate(date)
      }
      if (isValidDate(date) && isValidDate(isStartDate ? endDate : startDate)) {
      }
    }, 500)()
  }
  const fetchEducatorInfo = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = await get(`/admin/educator/${id}`);
      setEducatorInfo(res);
    } catch (e) {
      navigate('/shifts/error')
    }
    setIsLoading(false);
  }, [id, navigate]);
  useEffect(() => {
    fetchEducatorInfo();
  }, [fetchEducatorInfo]);
  useEffect(() => {
    fetchShift();
  }, [fetchShift]);
  useEffect(() => {
    fetchQualifications();
  }, []);


  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {' '}
          <CircularProgress />{' '}
        </Box>
      ) : (
        <Box>
          <BackButton
            title={'Back to educators'}
            onClick={() => {
              navigate('/educators')
            }}
          ></BackButton>
          <PaddingBox />
          {educatorInfo && educatorInfo.quickcare_educator ? (
            <Tags type={quickcareEducator}></Tags>
          ) : null}

          {educatorInfo && educatorInfo?.organisations?.length > 0 ? (
            <Box>
              <Row
                sx={{
                  marginTop: '8px',
                  alignItems: 'start',
                  padding: '8px',
                  borderRadius: '8px',
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.secondary.onSecondary,
                  width: 'fit-content'
                }}
              >
                <M3TitleMedium>Private educator for</M3TitleMedium>
                <PaddingBox />
                <Box>
                  {educatorInfo?.organisations?.map((organisation) => {
                    return (
                      <Row sx={{ paddingBottom: '8px' }}>
                        <CustomAvatar
                          src={organisation.logo_url}
                          sx={{
                            height: '16px',
                            width: '16px',
                            paddingRight: '8px'
                          }}
                        />
                        <M3BodyMedium>{organisation.name}</M3BodyMedium>
                      </Row>
                    )
                  })}
                </Box>
                <PaddingBox />
              </Row>
            </Box>
          ) : null}
          <PaddingBox />

          <Tags type={decideTagType(educatorInfo)}></Tags>
          <PaddingBox />
          <ListItem
            alignItems="flex-start"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ListItemAvatar>
              <CustomAvatar
                src={
                  educatorInfo && educatorInfo.avatar_url !== null
                    ? educatorInfo.avatar_url
                    : null
                }
              />
            </ListItemAvatar>
            <SpacedRow>
              <ListItemText
                primary={
                  <M3TitleLarge>
                    {educatorInfo
                      ? educatorInfo.first_name + ' ' + educatorInfo.last_name
                      : ''}
                  </M3TitleLarge>
                }
              />
              {currentTab === 0 ? (
                <ListItemSecondaryAction>
                  {educatorInfo && educatorInfo.email ? (
                    educatorInfo.deactivated ? (
                      <SuccessButton
                        text="Reactivate"
                        onClick={() => reActivateAnEducator(educatorInfo.id)}
                      />
                    ) : (
                      <RemoveButton
                        text="Deactivate"
                        onClick={() => deactivateAnEducator(educatorInfo.id)}
                      />
                    )
                  ) : null}
                </ListItemSecondaryAction>
              ) : null}
            </SpacedRow>
          </ListItem>
          <Box
            sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}
          >
            <CustomTabs
              variant="fullWidth"
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="info"
            >
              <CustomTab label={tabValues[0].name} />
              <CustomTab label={tabValues[1].name} />
            </CustomTabs>
          </Box>
          {currentTab === 0 && (
            <Box sx={{ p: 3 }}>
              <SpacedRow>
                <Row>
                  <Row>
                    <DatePickerTextField
                      label={'Start date'}
                      maxDate={endDate === '' ? getMaxDate() : endDate}
                      minDate={getMinDate()}
                      onChange={(event) => {
                        updateDateFilter(true, event.target.value)
                      }}
                      value={startDate}
                    ></DatePickerTextField>
                    <PaddingBox />
                    <DatePickerTextField
                      label={'End date'}
                      maxDate={getMaxDate()}
                      minDate={startDate === '' ? getMinDate() : startDate}
                      onChange={(event) => {
                        updateDateFilter(false, event.target.value)
                      }}
                      value={endDate}
                    ></DatePickerTextField>
                  </Row>
                </Row>
                <PaddingBox />
              </SpacedRow>
            </Box>
          )}
          {currentTab === 0 ? (
            <List>
              {shifts &&
                shifts.map((shiftIndex, index) => (
                  <div key={shiftIndex.id}>
                    <EducatorShiftItemList
                      onClick={() => {
                        navigateToShiftDetail(shiftIndex.id)
                      }}
                      params={{
                        shift: shiftIndex
                      }}
                    ></EducatorShiftItemList>
                  </div>
                ))}
            </List>
          ) : educatorInfo ? (
            <EducatorProfile
              educatorInfo={educatorInfo}
              qualificationList={qualificationsList}
              updateEducatorInfo={() => fetchEducatorInfo()}
            />
          ) : null}
        </Box>
      )}
    </div>
  )
};
export default EducatorDetail;

const EducatorProfile = ({ educatorInfo, qualificationList, updateEducatorInfo }) => {
  const [initialState, setInitialState] = useState({
    qualification: educatorInfo.qualification,
    document_url: educatorInfo.document_url,
    verified: educatorInfo.verified,
    qualification_date: educatorInfo.latest_qualification_date,
    previous_qualification: educatorInfo.previous_qualification,
    is_study_completed: educatorInfo.is_study_completed,
    ect_level: educatorInfo.ect_level,
    milestone_passed: educatorInfo.milestone_passed
  });

  const [qualificationLevel, setQualification] = useState(educatorInfo.qualification);
  const [document_url, setDocumentUrl] = useState(educatorInfo.document_url);
  const [verified, setVerified] = useState(educatorInfo.verified);
  const [qualificationDate, setQualificationDate] = useState(educatorInfo.latest_qualification_date);
  const [previousQualification, setPreviousQualification] = useState(educatorInfo.previous_qualification);
  const [isStudyCompleted, setIsStudyCompleted] = useState(educatorInfo.is_study_completed);
  const [milestonePassed, setMilestonePassed] = useState(educatorInfo.milestone_passed);
  const [ectLevel, setEctLevel] = useState(educatorInfo.ect_level);

  const [ectLevels, setEctLevels] = useState([]);
  const [percentCompletes, setPercentCompletes] = useState([]);

  useEffect(() => {
    setInitialState({
      qualification: educatorInfo.qualification,
      document_url: educatorInfo.document_url,
      verified: educatorInfo.verified,
      qualification_date: educatorInfo.latest_qualification_date,
      previous_qualification: educatorInfo.previous_qualification,
      is_study_completed: educatorInfo.is_study_completed,
      ect_level: educatorInfo.ect_level,
      milestone_passed: educatorInfo.milestone_passed
    });
  }, [educatorInfo]);

  useEffect(() => {
    fetchPercentCompletes();
    fetchEctLevels();
  }, []);

  const highestQualificationWidth = useMemo(() => {
    if (qualificationLevel === qualificationLevelId.UNQUALIFIED) return 12;
    if (qualificationLevel === qualificationLevelId.CERT_TOWARD ||
        qualificationLevel === qualificationLevelId.CERT3 ||
        qualificationLevel === qualificationLevelId.DIPLOMA) return 8;
    return 5;
  }, [qualificationLevel]);

  const changeQualification = (event) => {
    setQualification(event.target.value);
    setQualificationDate(null)
    setMilestonePassed('')
    setPreviousQualification(qualificationLevelId.NONE)
    if(event.target.value !== qualificationLevelId.ECT) {
      setEctLevel(null)
    }
  };

  const changeDocuments = (event) => {
    setDocumentUrl(event.target.value);
  };

  const handleCheckboxSightedChange = () => {
    setVerified(!verified);
  };

  const changeQualificationDate = (event) => {
    setQualificationDate(event.target.value === 'Invalid Date'? null: event.target.value);
  };

  const changePreviousQualification = (event) => {
    setPreviousQualification(event.target.value);
  };

  const changePercentComplete = (event) => {
    const selectedValue = event.target.value === '' ? null : event.target.value
    setMilestonePassed(selectedValue);
    setIsStudyCompleted(selectedValue);
  };

  const changeEctLevel = (event) => {
    setEctLevel(event.target.value);
  };

  const qualificationOptions = useMemo(() => {
    const baseOptions = [
      { key: 0, value: qualificationLevelId.NONE, label: 'None' },
      { key: qualificationLevelId.CERT3, value: qualificationLevelId.CERT3, label: 'Cert III' }
    ];
  
    return qualificationLevel === qualificationLevelId.ECT_TOWARRD
      ? [...baseOptions, { key: qualificationLevelId.DIPLOMA, value: qualificationLevelId.DIPLOMA, label: 'Diploma' }]
      : baseOptions;
  }, [qualificationLevel]);

  function validateValue() {
    if (qualificationLevel === null || qualificationLevel === undefined) {
      return true
    }
    const isNotValidDocument =
      educatorInfo.quickcare_educator && (!document_url || !verified)
    const notValidForm =
      isNotValidDocument ||
      (qualificationLevel !== qualificationLevelId.UNQUALIFIED &&
        !qualificationDate) ||
      ((qualificationLevel === qualificationLevelId.DIPLOMA_TOWARD ||
        qualificationLevel === qualificationLevelId.ECT_TOWARRD) &&
        (milestonePassed === null || milestonePassed === '')) ||
      (qualificationLevel === qualificationLevelId.ECT && !ectLevel)
    const formHasChanged = (
      qualificationLevel !== initialState.qualification ||
      document_url !== initialState.document_url ||
      verified !== initialState.verified ||
      qualificationDate !== initialState.qualification_date ||
      previousQualification !== initialState.previous_qualification ||
      isStudyCompleted !== initialState.is_study_completed ||
      ectLevel !== initialState.ect_level
    )
    return !notValidForm && formHasChanged;
  }

  const handleUpdateProfile = async () => {
    const body = {
      qualification: qualificationLevel,
      document_url: document_url,
      verified: verified,
      latest_qualification_date: qualificationDate,
      previous_qualification: (previousQualification < 0 || previousQualification === '' ) ? null: previousQualification,
      is_study_completed: isStudyCompleted,
      ect_level: ectLevel,
      milestone_passed: milestonePassed === ''? null: milestonePassed
    };

    try {
      await post(`/admin/users/educators/${educatorInfo.id}/update`, body).then(
        () => updateEducatorInfo()
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEctLevels = async () => {
    try {
      const ectLevels = await get('/manage/ect-levels', null)
      setEctLevels(ectLevels.ectLevels)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPercentCompletes = async () => {
    try {
      const data = await get('/manage/percent-completes', null)
      setPercentCompletes(data.percentCompletes)
    } catch (error) {
      console.error(error)
    }
  }

  const renderQualificationFields = () => {
    switch (qualificationLevel) {
      case qualificationLevelId.ECT:
        return (
          <>
          <EctLevelItem 
            xs={3}
            currentValue={ectLevel}
            onChange={changeEctLevel}
            options={
              ectLevels.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.value}
                  dense
                >
                  {option.label}
                </MenuItem>
              ))
            }
          />
          <Grid item xs={4}>
            <DatePickerTextField
              label={getQualificationDateLabel(qualificationLevel)}
              value={qualificationDate}
              onChange={changeQualificationDate}
            />
          </Grid>
          </>
        )
      case qualificationLevelId.DIPLOMA_TOWARD:
      case qualificationLevelId.ECT_TOWARRD:
        return (
          <>
            <PercentCompleteItem 
              xs={3}
              milestone_passed={ milestonePassed}
              onChange={changePercentComplete}
              percentCompletes={percentCompletes}
            />
            <PreviousQualificationItem 
              xs={12}
              currentValue={previousQualification || qualificationLevelId.NONE}
              onChange={changePreviousQualification}
              options={qualificationOptions.map(option => (
                <MenuItem key={option.key} value={option.value} dense>
                  {option.label}
                </MenuItem>
              ))}
            />
          </>
        )
      default:
        return null;
    }
  };

  return (
    <div>
      <h4>Phone number</h4>
      <CommonTextField
        label={'Phone number'}
        value={educatorInfo.phone_number}
        isDisable={true}
      />
      <h4>Email</h4>
      <CommonTextField
        label={'Email address'}
        value={educatorInfo.email}
        isDisable={true}
      />
      <h4>Qualification</h4>
      <Grid container spacing={2}>
        <Grid item xs={highestQualificationWidth}>
          <DropDownButton
            placeholder={'Highest Qualification'}
            currentValue={qualificationLevel}
            onChange={changeQualification}
            options={qualificationList.map((qualification) => (
              <MenuItem key={qualification.id} value={qualification.id} dense>
                {qualification.qualification}
              </MenuItem>
            ))}
          />
        </Grid>
        {qualificationLevel !== qualificationLevelId.UNQUALIFIED &&
          qualificationLevel !== qualificationLevelId.ECT && (
            <Grid item xs={4}>
              <DatePickerTextField
                label={getQualificationDateLabel(qualificationLevel)}
                value={qualificationDate}
                onChange={changeQualificationDate}
              />
            </Grid>
          )}
        {renderQualificationFields()}
      </Grid>
      {educatorInfo.quickcare_educator && (
        <>
          <h4>Documents</h4>
          <CommonTextField
            label={'Google drive link to supporting documents'}
            value={document_url}
            onChange={changeDocuments}
            isDisable={false}
          />
          <PaddingBox />
          <ApprovalCheckbox
            content={'All applicable docs have been sighted and uploaded'}
            onChange={handleCheckboxSightedChange}
            isChecked={verified}
          />
        </>
      )}
      <PaddingBox />
      <PaddingBox />
      <div style={{ textAlign: 'right' }}>
        <PrimaryButton
          text="Save"
          isDisabled={!validateValue()}
          onClick={handleUpdateProfile}
        />
      </div>
    </div>
  )
};
