import { useCallback, useContext, useEffect, useState } from "react";
import { get, postFile, put } from "../../../../utils/api";
import { PaddingBox, Row, SpacedRow } from "../../../reusable/Scaffolds";
import { Box, CircularProgress, TextField } from "@mui/material";
import { M3TitleLarge, M3TitleMedium } from "../../../reusable/TextStyles";
import { EditOutlined, Replay } from "@mui/icons-material";
import { ViewModeOrgDetailItem } from "./components/view_mode_org_detail_item";
import theme from "../../../../utils/theme";
import { PrimaryButton } from "../../../reusable/Buttons";
import { OrgDetailSkeletonLoading } from "./components/org_detail_skeleton_loading";
import { GoogleInput } from "../../../reusable/inputs/google_input";
import { ERROR, LOADING } from "../../../../utils/constants";
import { EdittableAvatar } from "./components/editable_avatar";
import { isValidEmail, isValidPhone } from "../../../../utils/regex";
import { isOwner } from "../../../../utils/auth";
import { OrgContext } from "../../../../utils/context";
import { CustomAvatar } from "../../../Layout";
const _ = require("lodash");

const defaultCentreValidate = {
  name: "",
  description: "",
  email: "",
  phone_number: "",
  address: "",
  abn: "",
};
export const OrganisationDetailTab = ({ orgID, setAlert }) => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [organisation, setOrganisation] = useState(null);
  const [editOrganisation, setEditOrganisation] = useState(null);
  const [valiDateState, setValiDateState] = useState(defaultCentreValidate);
  const [uploadingFile, setUploadingFile] = useState(null);
  const formatOrg = (res) => {
    return {
      ...res,
      name: res.name || "",
      description: res.description || "",
      logo: res.logo || "",
      abn: res.abn || "",
      email: res.email || "",
      logo_url: res.logo_url || "",
      phone_number: res.phone_number || "",
      address: res.address || "",
      lat: res.lat || 0,
      lng: res.lng || 0,
    };
  };
  const fetchOrganisation = useCallback(() => {
    setIsLoading(true);
    get(`/manage/organisations/${orgID}`)
      .then((res) => {
        setOrganisation(formatOrg(res));
        setEditOrganisation(formatOrg(res));
        setIsLoading(false);
      })
      .catch((error) => {
        setAlert(error.toString(), "error");
        setIsLoading(false);
      });
  }, [orgID, setAlert]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditOrganisation(() =>
      validateForm({ ...editOrganisation, [name]: value })
    );
  };
  const onPlaceSelected = (place) => {
    if (!place?.geometry) {
      setEditOrganisation((prevState) => {
        validateForm({
          ...prevState,
          address: place?.name,
          lat: 0,
          lng: 0,
        });
        return {
          ...prevState,
          address: place?.name,
          lat: 0,
          lng: 0,
        };
      });
      return;
    }

    const location = place.geometry.location;
    setEditOrganisation((prevState) => {
      validateForm({
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
      });
      return {
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
      };
    });
  };
  const handleImageFileChange = async (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setUploadingFile({
        file,
        src: reader.result,
        progress: LOADING,
      });
    };
    try {
      const response = await postFile("/upload", file);
      if (response.url) {
        setEditOrganisation((prevState) => {
          validateForm({
            ...prevState,
            logo_url: response.url,
          });
          return {
            ...prevState,
            logo_url: response.url,
          };
        });
        setUploadingFile(null);
      }
    } catch (error) {
      setUploadingFile({
        file: file,
        progress: ERROR,
      });
    }
  };
  const updateOrganisation = () => {
    setIsLoading(true);
    put(`/manage/organisations/${orgID}`, editOrganisation)
      .then((res) => {
        setOrganisation(formatOrg(res));
        setEditMode(false);
        setDisableSave(true);
        setAlert("Update organisation successfully", "success");
        setIsLoading(false);
      })
      .catch((error) => {
        setAlert(error.toString(), "error");
        setIsLoading(false);
      });
  };
  const validateForm = (updateState) => {
    setDisableSave(
      _.isEqual(organisation, updateState) ||
        !updateState.name ||
        !updateState.abn
    );
    setValiDateState(defaultCentreValidate);
    if (updateState.email && !isValidEmail(updateState.email)) {
      setValiDateState((prevState) => ({
        ...prevState,
        contact_email: "Invalid email",
      }));
    }
    if (updateState.phone_number && !isValidPhone(updateState.phone_number)) {
      setValiDateState((prevState) => ({
        ...prevState,
        contact_phone: "Invalid phone number",
      }));
    }
    if (!updateState.abn) {
      setValiDateState((prevState) => ({
        ...prevState,
        abn: "ABN is required",
      }));
    }
    if (!updateState.name) {
      setValiDateState((prevState) => ({
        ...prevState,
        name: "Organisation name is required",
      }));
    }
    if (
      (updateState.lat === 0 || updateState.lng === 0) &&
      updateState.address
    ) {
      setValiDateState((prevState) => ({
        ...prevState,
        address: "Address not found",
      }));
    }
    return updateState;
  };
  const org = useContext(OrgContext);
  useEffect(() => {
    fetchOrganisation();
  }, [fetchOrganisation]);
  return (
    <div>
      {isLoading ? (
        <OrgDetailSkeletonLoading />
      ) : (
        <Box>
          <PaddingBox />
          <Row>
            {uploadingFile ? (
              <div
                style={{
                  position: "relative",
                  width: "60px",
                  height: "60px",
                }}
              >
                {uploadingFile?.progress === ERROR ? (
                  <Replay
                    onClick={() => {
                      setUploadingFile(null);
                    }}
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 10,
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: 10,
                    }}
                  ></Replay>
                ) : (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 10,
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: 10,
                    }}
                    color="info"
                  ></CircularProgress>
                )}
                <CustomAvatar
                  src={uploadingFile.src}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                    display: "absolute",
                  }}
                  alt=""
                />
              </div>
            ) : (
              <EdittableAvatar
                src={editOrganisation?.logo_url}
                alt={editOrganisation?.name}
                isEditMode={editMode}
                onPickedImage={handleImageFileChange}
              />
            )}
            <PaddingBox /> <PaddingBox />
            <TextField
              sx={{
                display: editMode ? "" : "none",
              }}
              fullWidth
              name={"name"}
              error={valiDateState.name !== ""}
              helperText={valiDateState.name}
              label="Organisation name"
              value={editOrganisation["name"]}
              onChange={handleInputChange}
            ></TextField>
            <M3TitleLarge sx={{ display: editMode ? "none" : "" }}>
              {organisation.name}
            </M3TitleLarge>
            <PaddingBox />
            {isOwner(org) ? (
              <EditOutlined
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "20px",
                  cursor: "pointer",
                  display: editMode ? "none" : "",
                }}
                onClick={() => {
                  setEditMode(true);
                }}
              />
            ): null}
          </Row>
          <PaddingBox />
          {!editOrganisation || !editMode
            ? null
            : Object.keys(editOrganisation).map((key) => {
                if (
                  ["logo", "name", "id", "logo_url", "lat", "lng", "address_id"].includes(key)
                ) {
                  return null;
                }
                if (key === "address") {
                  return (
                    <GoogleInput
                      error={valiDateState.address !== ""}
                      helperText={valiDateState.address}
                      onPlaceSelected={onPlaceSelected}
                      value={editOrganisation.address}
                      onInput={handleInputChange}
                    />
                  );
                }
                return (
                  <TextField
                    key={key}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={valiDateState[key] !== ''}
                    helperText={valiDateState[key]}
                    inputProps={{
                      maxLength: key === 'description' ? 512 : 255
                    }}
                    multiline={key === 'description'}
                    label={
                      key.toLowerCase() === 'abn'
                        ? 'ABN'
                        : key.replace(/_/g, ' ').charAt(0).toUpperCase() +
                          key.replace(/_/g, ' ').slice(1)
                    }
                    name={key}
                    value={editOrganisation[key]}
                    onChange={handleInputChange}
                  />
                )
              })}
          {!organisation || editMode
            ? null
            : Object.keys(organisation).map((key) => {
                if (["logo", "name", "id", "logo_url", "address_id", "lat", "lng"].includes(key)) {
                  return null;
                }
                if (!organisation[key]) {
                  return null;
                }
                return (
                  <ViewModeOrgDetailItem
                    key={key}
                    label={
                      key.toLowerCase() === 'abn'? 'ABN' :   key.replace(/_/g, " ").charAt(0).toUpperCase() +
                      key.replace(/_/g, " ").slice(1)
                    }
                    value={organisation[key]}
                    onEditClick={() => {
                      if (isOwner(org)) {
                        setEditMode(true);
                      }
                    }}
                  ></ViewModeOrgDetailItem>
                );
              })}

          <PaddingBox />
          <SpacedRow style={{ display: editMode ? "" : "none" }}>
            <M3TitleMedium
              style={{ cursor: "pointer", color: theme.palette.info.main }}
              onClick={() => {
                setEditMode(false);
              }}
            >
              Cancel
            </M3TitleMedium>
            <PrimaryButton
              isDisabled={disableSave}
              text={"Save"}
              onClick={updateOrganisation}
            />
          </SpacedRow>
        </Box>
      )}
    </div>
  );
};
