import React from 'react'
import {
  Drawer,
  Avatar,
  List,
  ListItemButton,
  Container,
  Button,
  Box
} from '@mui/material'
import { Link } from 'react-router-dom'
import { M3BodyLarge, M3LabelMedium } from './reusable/TextStyles'
import Loading from './reusable/Loading'
import styled from '@emotion/styled'
import LogoutIcon from '@mui/icons-material/Logout'
import CheckListIcon from '@mui/icons-material/Checklist'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LoginIcon from '@mui/icons-material/Login'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import { ReactComponent as Logo } from '../assets/Logo.svg'
import { useLocation } from 'react-router-dom'
import theme from '../utils/theme'

const Layout = ({
  children,
  isLoggedIn,
  isAdmin,
  onLogout,
  loading,
  currentUser,
  currentRole,
}) => {
  const location = useLocation()
  const getLinks = () => {
    if (!isLoggedIn) {
      return [
        {
          route: '/login',
          name: 'Login',
          icon: <LoginIcon />
        }
      ]
    }
    if (isAdmin) {
      return [
        {
          route: '/educators',
          name: 'Educators',
          icon: <SchoolOutlinedIcon />
        },
        {
          route: '/applications',
          name: 'Applications',
          icon: <CheckListIcon />
        },
        {
          route: '/logins-manage',
          name: 'Manage logins',
          icon: <SupervisorAccountOutlinedIcon />
        }
      ]
    }


    if (['owner', 'manager', 'support'].includes(currentRole)) {
      return [
        {
          route: '/shifts',
          name: 'Shifts',
          icon: <ListAltIcon />
        },
        {
          route: '/educators',
          name: 'Educator management',
          icon: <SchoolOutlinedIcon />
        },
        {
          route: '/organisation',
          name: 'Organisation',
          icon: <LocationCityOutlinedIcon />
        },
        {
          route: '/logins-manage',
          name: 'Manage logins',
          icon: <SupervisorAccountOutlinedIcon />
        }
      ]
    }
   
    return []
  }   
  const isHidden = location.pathname.includes('/signup') || location.pathname.includes('/reset/');


  return (
    <div style={{ display: 'flex' }}>
      {isHidden ? null : (
        <CustomDrawer
          variant="permanent"
          style={{
            display:
              !isAdmin &&
              (currentRole === 'educator' || currentRole === null) &&
              isLoggedIn
                ? 'none'
                : ''
          }}
        >
          <LogoContainer>
            <Link to="/">
              <Logo />
            </Link>
          </LogoContainer>
          <List>
            {getLinks().map((link) => (
              <SelectedItem
                key={link.route}
                component={Link}
                route={link.route}
                sx={{
                  backgroundColor:
                    location.pathname === link.route
                      ? theme.palette.secondary.main
                      : null
                }}
                selected={location.pathname === link.route}
                to={link.route}
              >
                <Row>
                  {link.icon}
                  <M3BodyLarge style={{ paddingLeft: 16 }}>
                    {link.name}
                  </M3BodyLarge>
                </Row>
              </SelectedItem>
            ))}
          </List>
          {isLoggedIn && (
            <ProfileSection>
              <Row>
                <CustomAvatar
                  style={{ marginRight: 16 }}
                  src={currentUser?.avatar_url}
                  {...stringAvatar(
                    `${currentUser?.first_name} ${currentUser?.last_name}`
                  )}
                />
                <List>
                  <M3LabelMedium>Logged in as</M3LabelMedium>
                  <M3BodyLarge>
                    {currentUser?.first_name} {currentUser?.last_name}
                  </M3BodyLarge>
                </List>
                <Button
                  style={{
                    marginLeft: 16,
                    padding: '8px 16px',
                    textTransform: 'none',
                    color: theme.palette.primaryContainer.onPrimary,
                    backgroundColor: theme.palette.primaryContainer.main
                  }}
                  onClick={onLogout}
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </Row>
            </ProfileSection>
          )}
        </CustomDrawer>
      )}
      <Container maxWidth="lg" style={{ flexGrow: 1, padding: '20px' }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box
              sx={{
                height: '48px'
              }}
            />
            {children}
          </>
        )}
      </Container>
    </div>
  )
}

const LogoContainer = styled.div`
  padding: 32px;
`
export const CustomAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.onPrimary};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`
const CustomDrawer = styled(Drawer)`
  width: 370px;
  z-index: 900;
  justify-content: space-between;
`
const ProfileSection = styled.div`
  margin-top: auto;
  background-color: ${(props) => props.theme.palette.surfaceContainerLow.main};
  padding: 20px;
`
const SelectedItem = styled(ListItemButton)`
  color: ${(props) => props.theme.colors.text};
  margin: 5px;
  margin-top: ${(props) =>
    props.route === 'Organisations-Private' ? '60vh' : '5px'};
`
export default Layout

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}
