/* eslint-disable no-useless-escape */
export const isValidEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value)
}
export const isValidPhone = (value) => {
  const phoneRegex = /^\d{10}$/
  return phoneRegex.test(value)
}


export function isValidHttpsUrl(string) {
  const urlRegex = /^https:\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/
  return urlRegex.test(string)
}