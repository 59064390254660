import { useCallback, useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { get, post } from '../../../../../utils/api'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FilteredSelectionComponent from '../../../../reusable/inputs/filtered_selection'
import { PaddingBox, SpacedRow } from '../../../../reusable/Scaffolds'
import { CustomAvatar, Row } from '../../../../Layout'
import { CloseButton, PrimaryButton } from '../../../../reusable/Buttons'
import { stringAvatar } from '../../../../../utils/helper_functions'

const {
  Modal,
  Box,
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemText,
  CircularProgress
} = require('@mui/material')
const { modalBoxStyles } = require('../../../../reusable/ModalStyles')
const {
  M3TitleLarge,
  M3LabelMedium
} = require('../../../../reusable/TextStyles')

export const CreateCentreAssignmentGroup = ({
  centre_id,
  org_id,
  open,
  onClose,
  allOrgEducators,
  assignmentGroupId
}) => {
  const [groupEducators, setgroupEducators] = useState([])
  const [orgEducators, setOrgEducators] = useState([])
  const [edit, setEdit] = useState(false)
  const [bufferGroupEducators, setBufferGroupEducators] = useState([])
  const [groupId, setGroupId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentCentreId, setCurrentCentreId] = useState(0)

  const filterNotExistingEducatorsFromOrg = useCallback(
    (educators) => {
      const existingEducator = allOrgEducators.filter((educator) =>
        educators.find((e) => e.id === educator.id)
      )
      const existingEducatorIDs = existingEducator.map(
        (educator) => educator.id
      )
      return allOrgEducators.filter(
        (educator) => !existingEducatorIDs.includes(educator.id)
      )
    },
    [allOrgEducators]
  )
  const onModalClose = (reload, message) => {
    setGroupId(null)
    onClose(reload, message)
  }
  const fetchCentreAssignmentGroupInfo = useCallback(async () => {
    let body = { centre_id }
    if (!centre_id) {
      body = null
    }
    setIsLoading(true)
    try {
      const res = await get(
        `/manage/organisation/${org_id}/get_current_centre_group_info`,
        null,
        body
      )
      setgroupEducators(res.educators)
      setOrgEducators(filterNotExistingEducatorsFromOrg(res.educators))
      setEdit(!!res.educators.length)
      setGroupId(res.id)
    } catch (error) {
      setgroupEducators([])
      setOrgEducators(filterNotExistingEducatorsFromOrg([]))
      setEdit(false)
    }
    setIsLoading(false)
  }, [org_id, centre_id, filterNotExistingEducatorsFromOrg])

  const addGroupEducators = (educators) => {
    const educatorsIDs = educators.map((e) => e.id)
    setOrgEducators(orgEducators.filter((e) => !educatorsIDs.includes(e.id)))
    setgroupEducators(groupEducators.concat(educators))
    setBufferGroupEducators([])
  }
  const removeGroupEducators = (educators) => {
    setgroupEducators(groupEducators.filter((e) => e.id !== educators.id))
    setOrgEducators(orgEducators.concat(educators))
  }
  const updateOrAddGroup = async () => {
    try {
      const group = {
        centre_id,
        id: assignmentGroupId,
        educator_ids: groupEducators.map((e) => e.id)
      }
      if (groupId) {
        group.id = groupId
      }
      const res = await post(
        `/manage/organisation/${org_id}/create_or_update_group`,
        group
      )
      onModalClose(true, res.message)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCentreAssignmentGroupInfo()
  }, [fetchCentreAssignmentGroupInfo])

  useEffect(() => {
    if (currentCentreId !== centre_id) {
      setgroupEducators([])
      setCurrentCentreId(currentCentreId)
    }
  }, [centre_id, currentCentreId])

  return (
    <Modal open={open} onClose={() => onModalClose(false)} sx={{ zIndex: 999 }}>
      <Box sx={modalBoxStyles}>
        <M3TitleLarge>
          Update favourite educators for my centre
        </M3TitleLarge>
        <PaddingBox />
        <Row>
          <FilteredSelectionComponent
            options={orgEducators}
            onChange={(e, value) => setBufferGroupEducators(value)}
            inputValue={bufferGroupEducators}
          />
          <PaddingBox />
          <AddCircleIcon
            sx={{ cursor: 'pointer' }}
            color={bufferGroupEducators.length > 0 ? 'primary' : 'disabled'}
            onClick={() => {
              if (bufferGroupEducators.length > 0) {
                addGroupEducators(bufferGroupEducators)
              }
            }}
          />
        </Row>
        <PaddingBox />
        <List
          style={{
            maxHeight: '400px',
            overflow: 'auto',
            width: '100%'
          }}
        >
          {groupEducators.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px'
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <M3LabelMedium>No educators in this group</M3LabelMedium>
              )}
            </Box>
          ) : (
            groupEducators.map((educator) => (
              <ListItem key={educator.id}>
                <ListItemAvatar>
                  <CustomAvatar
                    {...stringAvatar(
                      educator.first_name + ' ' + educator.last_name
                    )}
                    src={
                      educator.avatar_url !== null ? educator.avatar_url : null
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={educator.name}
                  secondary={educator.email}
                />
                <IconButton>
                  <DeleteIcon onClick={() => removeGroupEducators(educator)} />
                </IconButton>
              </ListItem>
            ))
          )}
        </List>
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={() => onModalClose(false)} text={'Cancel'} />
          <PrimaryButton
            isDisabled={groupEducators.length === 0 && !edit}
            text={'Update'}
            onClick={updateOrAddGroup}
          />
        </SpacedRow>
      </Box>
    </Modal>
  )
}
