import { useEffect } from "react";

export const DownloadPage = () => {
    useEffect(() => {
      /// Current page is open by IOS devices
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i)
      ) {
        window.location.href =
          'https://apps.apple.com/au/app/quickcare/id6464295842?platform=iphone'
      }
      /// Current page is open by Android devices
      if (navigator.userAgent.match(/Android/i)) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.quickcare.hr'
      }
    }, [])
    return (
      <>
        <div className="header"></div>
        <div className="logo">
          <img
            width={600}
            height={152}
            src="https://quickcarehr.com/wp-content/uploads/2024/08/quickcare-logo.webp"
            alt=""
          />
        </div>
        <div className="main">
          <div className="support">
            <div className="sectiontitle">
              <h1>Download the QuickCare app</h1>
            </div>
            <div className="sectioncontent">
              <div className="downloaddetails">
                <table
                  cellpadding="2"
                  cellmargin="0"
                  border="0"
                  style={{ borderCollapse: 'collapse' }}
                >
                  <tr>
                    <td>
                      <b>Google Play</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://play.google.com/store/apps/details?id=com.quickcare.hr">
                        <img
                          alt="Get it on Google Play"
                          width="300"
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Apple Store</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://apps.apple.com/au/app/quickcare/id6464295842?platform=iphone">
                        <img
                          width="250"
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us"
                          alt="Download on the App Store"
                          style={{ margin: '20px' }}
                        />
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="footerspace">&nbsp;</div>
        <div className="footer">
          <div className="footertop"></div>
          <div className="footercontent">
            <p className="copyright">
              &copy; Carenow Technologies Pty Ltd 2024
            </p>
          </div>
        </div>
      </>
    )
};