import React, { useState } from 'react';
import { Alert, Button, TextField, Paper, Typography, Container, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import { post, setLocalStorageToken } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { cleanInputForDB } from '../../utils/helper_functions';
import { CommonTextField } from '../reusable/TextField';
import { PaddingBox } from '../reusable/Scaffolds';
import { CloseButton, PrimaryButton } from '../reusable/Buttons';
import OneTimePasswordLogin from './Logins/components/one_time_password'

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [oneTimeLogin, setOneTimeLogin] = useState(true);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const { token } = await post('/login', { email: cleanInputForDB(email), password: cleanInputForDB(password) });
      if (!token) throw new Error('Login failed');
      setLocalStorageToken(token);
      onLoginSuccess(token);
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSendOtp = async (identifier, authenticationType) => {
    setLoading(true);
    try {
      await post('/passwordless/authenticate', {
        identifier,
        authenticationType
      });
      setError(null);
      return 'success'
    } catch (err) {
      setError(err.message);
      return err.message
    } finally {
      setLoading(false);
    }
  }

  const handleOtpComplete = async (otpEmail, completedOtp, authenticationType) => {
    setLoading(true);
    setError(null);
    try {
      const response = await post('/passwordless/verify-code', {
        identifier: otpEmail,
        code: completedOtp,
        authenticationType
      });
      setLocalStorageToken(response.token);
      onLoginSuccess(response.token);
    } catch (err) {
      if (err?.errorCode !== 'INVALID_REQUEST') {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      await post('/password/reset/request', { email: resetEmail });
      setResetSuccess(true);
      setResetError(null);
    } catch (err) {
      setResetError(err.message);
      setResetSuccess(false);
    }
  };

  const handleError = (message) => {
    setError(message);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">{oneTimeLogin ? 'Sign in with a code' : 'Login'}</Typography>
        <form onSubmit={handleLogin} style={{ width: '100%', marginTop: '8px' }}>
          <div style={{ display: oneTimeLogin ? 'block' : 'none' }}>
            <OneTimePasswordLogin
              handleSendOtp={handleSendOtp}
              handleOtpComplete={handleOtpComplete}
              updateError={handleError}
              currentError={error}
            />
            <Link
              href="#"
              variant="body2"
              style={{ marginTop: '10px', display: 'block', textAlign: 'center' }}
              onClick={() => {
                  setError('')
                  setOneTimeLogin(false)
                }
              }
            >
              Sign in with a password instead
            </Link>
          </div>

          <div style={{ display: oneTimeLogin ? 'none' : 'block' }}>
            <CommonTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <PaddingBox />
            <CommonTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <PaddingBox />
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>Login</Button>
            <Link href="#" variant="body2" style={{ marginTop: '10px', display: 'block' }} onClick={() => setOpen(true)}>Forgot password?</Link>
            <Link href="#" variant="body2" style={{ marginTop: '10px', display: 'block' }} onClick={() => {
                  setError('')
                  setOneTimeLogin(true)
                }
            }>Sign in with a code instead</Link>
          </div>

          {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}
          <PaddingBox />
        </form>
      </Paper>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle style={{ paddingBottom: '0' }}>Reset Password</DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
            autoFocus
          />
          {resetError && <Alert severity="error" style={{ marginTop: '10px' }}>{resetError}</Alert>}
          {resetSuccess && <Alert severity="success" style={{ marginTop: '10px' }}>Password reset request sent successfully.</Alert>}
        </DialogContent>
        <DialogActions>
          <CloseButton onClick={() => setOpen(false)} text={'Cancel'} />
          <PrimaryButton onClick={handleResetPassword} text={'Reset'} />
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Login;