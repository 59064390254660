import React, { useState } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import { modalBoxStyles } from "../reusable/ModalStyles";
import { PaddingBox, SpacedRow } from "../reusable/Scaffolds";
import { M3HeadlineSmall } from "../reusable/TextStyles";
import { stringAvatar } from "../../utils/helper_functions";
import { CustomAvatar } from "../Layout";
import { CloseButton } from "../reusable/Buttons";
const AddBlacklistForm = ({
  open,
  setOpen,
  educatorInfo,
  handleAddBlacklist,
}) => {
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setOpen(false);
    setReason("");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box sx={modalBoxStyles}>
        <CustomAvatar
          {...stringAvatar(
            educatorInfo.first_name + " " + educatorInfo.last_name
          )}
          src={educatorInfo.avatar_url}
          sx={{ width: 80, height: 80 }}
        />
        <PaddingBox />
        <M3HeadlineSmall>
          {educatorInfo.first_name}{" "}
          {educatorInfo.prefered_name && `(${educatorInfo.prefered_name}) `}
          {educatorInfo.last_name}
        </M3HeadlineSmall>
        <PaddingBox />
        <TextField
          value={reason}
          fullWidth
          margin="normal"
          label="Blacklist Reason"
          type="text"
          onChange={(e) => setReason(e.target.value)}
        />
        <PaddingBox />
        <PaddingBox />
        
        <SpacedRow>
        <CloseButton onClick={handleClose} text={'Cancel'} />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={reason === ""}
            onClick={() => {
              handleAddBlacklist(reason)
              setReason('')
            }}
          >
            Blacklist
          </Button>
        </SpacedRow>
      </Box>
    </Modal>
  );
};

export default AddBlacklistForm;
