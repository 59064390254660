import React, { useState, useContext } from 'react'
import {
  Box} from '@mui/material'
import { AddButton } from '../../reusable/Buttons'
import {
  CustomTab,
  CustomTabs,
  PaddingBox,
  SpacedRow
} from '../../reusable/Scaffolds'
import { OrgContext } from '../../../utils/context'
import { isOwner } from '../../../utils/auth'
import PoliciesTab from './PoliciesTab'
import CentresTab from './CentresTab'
import CentresModal from './CentresTab/components/create_centre_modal'
import { OrganisationDetailTab } from './OrganisationDetailTab'
import { M3TitleLarge } from '../../reusable/TextStyles'
import theme from '../../../utils/theme'


const MainOrganisationTab = {
  name: 'Main organisation',
  value: 0
}

const AllLocationsTab = {
  name: 'All centres',
  value: 1
}

const OrganisationPoliciesTab = {
  name: 'Organisation policies',
  value: 2
}

const Organisation = ({ setAlert }) => {
  const [tabValue, setTabValue] = React.useState(0)
  const [centresModal, setCentresModal] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(0)
  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const forceUpdateAction = () => {
    setForceUpdate((prev) => prev + 1)
  }

  return (
    <div>
      <SpacedRow>
        <M3TitleLarge>Organisation</M3TitleLarge>
        {(isOwner(org) & [OrganisationPoliciesTab.value, AllLocationsTab.value].includes(tabValue) )? (
          <AddButton
            text={
              tabValue === OrganisationPoliciesTab.value
                ? 'Add policy'
                : 'Add centre'
            }
            onClick={() => {
              tabValue === OrganisationPoliciesTab.value
                ? setAddDialogOpen(true)
                : setCentresModal(true)
            }}
          />
        ) : null}
      </SpacedRow>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}>
        <CustomTabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="info"
          centered
        >
          <CustomTab label={MainOrganisationTab.name} />
          <CustomTab label={AllLocationsTab.name} />
          <CustomTab label={OrganisationPoliciesTab.name} />
        </CustomTabs>
      </Box>
      <PaddingBox />
      {tabValue === OrganisationPoliciesTab.value ? (
        <PoliciesTab
          setAlert={setAlert}
          addDialogOpen={addDialogOpen}
          setAddDialogOpen={setAddDialogOpen}
        />
      ) : tabValue === AllLocationsTab.value ? (
        <CentresTab setAlert={setAlert} update={forceUpdate} />
      ) : <OrganisationDetailTab setAlert={setAlert} orgID={org_id} />}
     <CentresModal
        open={centresModal}
        orgId={org_id}
        editable={isOwner(org)}
        onClose={
          () => {
            setCentresModal(false)
            forceUpdateAction()
          }
        }
      />
    </div>
  )
}

export default Organisation 


