import { ReactComponent as Logo } from '../../../assets/Logo.svg'
import theme from '../../../utils/theme'
import { PaddingBox } from '../../reusable/Scaffolds'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { M3BodyLarge,  M3TitleMedium } from '../../reusable/TextStyles'
import LanguageIcon from '@mui/icons-material/Language';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.quickcare.hr'
const appleStoreLink = 'https://apps.apple.com/us/app/quickcare/id6464295842'
const quickcareLink = 'https://quickcarehr.com'
const SignupSuccess = ({ orgInvite }) => {
  const navigate = useNavigate();
  return (
    <div>
      <a href={quickcareLink}>
        <Logo />
      </a>
      <div style={{ height: '40px' }} />
      <AutoFixHighIcon
        style={{ fontSize: 20, color: theme.palette.primary.main }}
      />
      <M3TitleMedium style={{ color: theme.palette.primary.main }}>
        Welcome to QuickCare!
      </M3TitleMedium>
      <PaddingBox></PaddingBox>

      <M3TitleMedium>
        If you have not done so already, please download and install the app
        onto your mobile phone.
      </M3TitleMedium>
      <PaddingBox></PaddingBox>
      <div>
        <a href={googlePlayLink}>
          <img
            alt="Get it on Google Play"
            width={220}
            height={100}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
        <br />
        <a href={appleStoreLink}>
          <img
            width={220}
            height={100}
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us"
            alt="Download on the App Store"
          />
        </a>
      </div>
      <PaddingBox></PaddingBox>
      {orgInvite === 'true' && (
        <div>
          <M3TitleMedium>
          As part of a childcare organisation, you can also access the QuickCare web app, which allows you to manage shifts and messages, as well as manage organisation level settings and information.
          </M3TitleMedium>
          <PaddingBox></PaddingBox>
          <Box
            sx={{
              border: '1px solid #E0E0E0',
              borderRadius: '4px',
              padding: '12px',
              cursor: 'pointer',
              width: 'fit-content'
            }}
            onClick={() => {
              /// open link on the same tab
              navigate('/login');
            }}
          >
            <LanguageIcon
              style={{ fontSize: 20, color: theme.palette.primary.main }}
            />
            <M3TitleMedium style={{ color: theme.palette.primary.main }}>
              QuickCare Web App
            </M3TitleMedium>
          </Box>
        </div>
      )}

      <PaddingBox></PaddingBox>
      <M3TitleMedium>Thanks, and happy QuickCaring!</M3TitleMedium>
      <br />
      <br />
      <M3BodyLarge>The QuickCare Team</M3BodyLarge>
    </div>
  )
}

export default SignupSuccess