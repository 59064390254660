import React, { useState } from 'react';
import { Container, Paper, Button, Alert, Typography } from '@mui/material';
import { post, setLocalStorageToken } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { PaddingBox } from '../reusable/Scaffolds';
import { CommonTextField } from '../reusable/TextField';

const ResetPassword = ({ code }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleResetConfirm = async () => {
    setLoading(true);
    setError(null);

    try {
      const { token } = await post('/password/reset/confirm', { code, password });
      if (!token) throw new Error('Reset failed');
      setLocalStorageToken(token);
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">Reset Password</Typography>
        <input type="hidden" name="code" value={code} />
        <PaddingBox></PaddingBox>
          <CommonTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <PaddingBox></PaddingBox>
        {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}
        <Button onClick={handleResetConfirm} color="primary" disabled={loading} variant="contained" fullWidth>Confirm</Button>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
