import theme from "../../../../../utils/theme";
import { useRef } from "react";
import { EditOutlined } from "@mui/icons-material";
import { CustomAvatar } from "../../../../Layout";

export const EdittableAvatar = ({ src, alt, onPickedImage, isEditMode }) => {
  const inputFile = useRef(null);

  return (
    <div
      style={{
        position: "relative",
        width: "64px",
        height: "60px",
        cursor: "pointer",
      }}
      onClick={() => {
        if (isEditMode) {
          inputFile.current.click();
        }
      }}
    >
      <input
        ref={inputFile}
        type="file"
        hidden
        onChange={onPickedImage}
      ></input>
      <CustomAvatar
        style={{ border: `2px solid ${theme.palette.primary.main}` }}
        src={src}
        alt={alt}
        sx={{ width: 60, height: 60 }}
      />
      <div
        style={{
          position: "absolute",
          top: "2px",
          right: "0px",
          width: "20px",
          height: "20px",
          backgroundColor: theme.palette.info.main,
          borderRadius: "50%",
          border: "2px solid white",
          display: isEditMode ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EditOutlined style={{ color: "white", fontSize: "16px" }} />
      </div>
    </div>
  );
};
