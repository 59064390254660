import { Box, Skeleton, Typography } from "@mui/material"
import theme from "../../../../../utils/theme"

export const ViewModeOrgDetailItem = ({ label, value, onEditClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        marginTop: '12px',
        padding: '12px',
        cursor: 'pointer',
        color: theme.palette.onSurface.main,
        backgroundColor: theme.palette.surface.main
      }}
      onClick={onEditClick}
    >
      <div style={{ flex: 1 }}>
        {!label ? (
          <Skeleton width={'20%'}></Skeleton>
        ) : (
          <Typography variant="subtitle1">{label}</Typography>
        )}
        {!value ? (
          <div>
            <Skeleton></Skeleton>
            <Skeleton width={'40%'}></Skeleton>
          </div>
        ) : (
          <Typography
            variant="body2"
            style={{ whiteSpace: 'pre-wrap' }}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 5
            }}
          >
            {value}
          </Typography>
        )}
      </div>
    </Box>
  )
}
