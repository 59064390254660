import React, { useState } from 'react'
import { Column, SpacedRow } from '../../../../reusable/Scaffolds'
import {
  M3BodyLarge,
  M3BodyMedium,
  M3LabelLarge,
  M3LabelSmall
} from '../../../../reusable/TextStyles'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import {
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Skeleton
} from '@mui/material'

import { stringAvatar } from '../../../../../utils/helper_functions'
import { CustomAvatar } from '../../../../Layout'

export function LoginItemList({ params, onClick }) {
  const { user } = params
  const [isAssignedCentreOpen, setAssignedCentreOpen] = useState(false)

  const toggleAssignedCentre = () => {
    setAssignedCentreOpen(!isAssignedCentreOpen)
  }

  const centreList = user.centres

  return (
    <ListItemButton
      selected={false}
      onClick={() => {
        onClick()
      }}
      alignItems="flex-start"
      disableRipple
    >
      {user.id ? (
        <ListItemAvatar>
          <CustomAvatar
            {...stringAvatar((user.first_name || '') + ' ' + (user.last_name || ''))}
            src={user.avatar_url !== null ? user.avatar_url : null}
          />
        </ListItemAvatar>
      ) : null}
      <SpacedRow>
        <div style={{ flexDirection: 'column' }}>
          <div
            style={{
              marginLeft: 'auto',
              color: 'green',
              fontSize: 12,
              fontWeight: 700
            }}
          >
            {user.access.charAt(0).toUpperCase() + user.access.slice(1)}
          </div>
          <ListItemText
            primary={
              <M3BodyLarge>
                {user.first_name} {user.last_name}
              </M3BodyLarge>
            }
            secondary={
              centreList.length > 0 && (
                <span
                  style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleAssignedCentre()
                  }}
                >
                  <M3LabelLarge>Assigned centre</M3LabelLarge>

                  {isAssignedCentreOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                </span>
              )
            }
          />
          {isAssignedCentreOpen && (
            <Column>
              {centreList.map((centres, index) => (
                <M3BodyMedium key={index}>{centres.name}</M3BodyMedium>
              ))}
            </Column>
          )}
        </div>
        <ListItemSecondaryAction>
          <M3LabelSmall>
           {user.email} 
          </M3LabelSmall>
        </ListItemSecondaryAction>
      </SpacedRow>
    </ListItemButton>
  )
}

export function SkeletonLoginItemList() {
  return (
    <div>
     {[0, 1, 2, 3].map((index) => (
      <ListItemButton key={index} selected={false} disableRipple>
        <ListItemAvatar>
          <Skeleton variant="circular" width={40} height={40} />
        </ListItemAvatar>
        <SpacedRow>
          <div style={{ flexDirection: 'column' }}>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={100} />
          </div>
          <ListItemSecondaryAction>
            <Skeleton variant="text" width={100} />
          </ListItemSecondaryAction>
        </SpacedRow>
      </ListItemButton>))}
    </div>
  )
}