import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import {
  SingleInputTimeRangeField,
  LocalizationProvider,
  DatePicker
} from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import React from 'react'
export function TimeRangePicker({
  startTime,
  endTime,
  setData,
  isDisabled = false
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDateRangeSelector
        label="Time start and end"
        value={[
          dayjs(`2022-04-21T${startTime}`),
          dayjs(`2022-04-21T${endTime}`)
        ]}
        variant={isDisabled ? 'filled' : 'outlined'}
        margin="none"
        hiddenLabel={isDisabled}
        disabled={isDisabled}
        onChange={(newValue) => {
          return setData(newValue)
        }}
      />
    </LocalizationProvider>
  )
}
export function SearchBox({ placeholder, onChange, value }) {
  return (
    <SearchInput
      id="outlined-adornment-password"
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      margin="dense"
      value={value}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  )
}

export function CommonTextField({
  label,
  value,
  onChange,
  onKeyDown,
  icon,
  isDisable,
  required,
  type = 'text',
  isMultiline = false,
  startAdornment = null,
  name,
  inputRef,
  error = false,
  rows = 1
}) {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl fullWidth variant={isDisable ? 'filled' : 'outlined'}>
      <TextField
        inputRef={inputRef}
        name={name}
        disabled={isDisable}
        multiline={isMultiline}
        rows={rows}
        required={required}
        variant={isDisable ? 'filled' : 'outlined'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : (
                icon
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )
        }}
        color={error ? 'error' : value ? 'success' : null}
        step={0.5}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type={type === 'password' && showPassword ? 'text' : type}
        label={label}
      />
    </FormControl>
  )
}
const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

export function DatePickerTextField({
  onChange,
  value,
  label,
  minDate,
  maxDate
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: '100%' }}
        label={label}
        onChange={(e) => {
          onChange({
            target: {
              value: dayjs(e).format('YYYY-MM-DD')
            }
          })
        }}
        format='DD/MM/YYYY'
        value={dayjs(value)}
        minDate={minDate ? dayjs(minDate) : null}
        maxDate={maxDate ? dayjs(maxDate) : null}
        shouldDisableDate={(date, position) => {
          if (position === 'end') {
            return false;
          }
          return isWeekend(date);
        }}
      />
    </LocalizationProvider>
  )
}

export function TextFieldNumber({ value, label, onChange }) {
  return (
    <TextField
      fullWidth
      margin="none"
      id="outlined-number"
      label={label}
      type="number"
      InputProps={{ inputProps: { min: '0', max: '99', step: '1' } }}
      variant="outlined"
      onChange={onChange}
      value={value}
    />
  )
}

export function TextFieldMoney({ value, label, onChange }) {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && Number(inputValue) >= 0) {
      const numericValue = Number(inputValue);
      if (numericValue > 999) {
        onChange({ ...e, target: { ...e.target, value: "999" } });
      } else {
        onChange(e);
      }
    }
  };
  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        label={label}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        onChange={handleInputChange}
        value={value}
      />
    </FormControl>
  );
}

const StyledDateRangeSelector = styled(SingleInputTimeRangeField)`
  width: 100%;
  & .MuiFilledInput-input {
    padding-top: 28px;
    padding-bottom: 6px;
  }
`
export const SearchInput = styled(OutlinedInput)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
  margin-top: 4px;
  height: 56px;
  width: 40%;
  max-width: 600px;
  padding-left: 32px;
  border-radius: 28px;
  background-color: white;
`