import { styled as muiStyled } from '@mui/material';


export const M3Body = muiStyled('span')`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
    letter-spacing: 0.1px;
`;
export const M3BodyMedium = muiStyled('span')`
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `;
  
export const M3BodySmall = muiStyled('div')`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  `;

  
export const M3LabelMedium = muiStyled('div')`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.palette.text.secondary};
`;
export const M3LabelSmall = muiStyled('div')`
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const M3LabelLarge = muiStyled('span')`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`;


export const M3BodyLarge = muiStyled('div')`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.palette.text.main};
`;

export const M3TitleSmall = muiStyled('div')`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const M3TitleMedium = muiStyled('div')`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const M3TitleLarge = muiStyled('div')`
  font-family: Roboto;
  font-size: 26px; 
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.palette.text.primary};
`;

export const M3HeadlineSmall = muiStyled('div')`
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;