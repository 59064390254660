import React, { useState, useMemo, useCallback } from 'react'
import {
  Typography,
  Modal,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Grid
} from '@mui/material'
import { modalBoxStyles } from '../reusable/ModalStyles'
import { PaddingBox } from '../reusable/Scaffolds'
import { formatToLocalPhoneNumber, hasNoSpaces } from '../../utils/helper_functions'
import { get } from '../../utils/api'
import { CommonTextField } from '../reusable/TextField'
import { GoogleInput } from '../reusable/inputs/google_input'
import { DatePickerTextField } from "../reusable/TextField"
import { PercentCompleteItem } from "../reusable/QualificationItem"
import { qualificationLevelId } from "../..//utils/constants"
import { CloseButton, PrimaryButton } from '../reusable/Buttons'
import { Row } from '../Layout'
import { getQualificationDateLabel, isValidPhoneNumber } from "../../utils/helper_functions";
const defaultUser = {
  first_name: '',
  last_name: '',
  email: '',
  qualification: 0,
  phone_number: '',
  payroll_id: '',
  address: '',
  lng: 0,
  lat: 0,
  latest_qualification_date: null,
  ect_level: '',
  previous_qualification: ''
}
const OrgEducatorInviteForm = ({
  open,
  setOpen,
  handleSaveUser,
  error,
  setError,
  qualificationLevels,
  ectLevelList,
  percentCompletes,
  isAdmin,
  isLoading,
}) => {
  const [emailError, setEmailError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [cloudPayrollIdError, setCloudPayrollIdError] = useState(false)
  const [disableSave, setDisableSave] = useState(true)
  const [user, setUser] = useState(defaultUser)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const updatedUser = {
      ...user,
      phone_number: formatToLocalPhoneNumber(user.phone_number)
    };
    handleSaveUser(updatedUser)
  }
  
  const onPlaceSelected = (place) => {
    if (!place?.geometry) {
      setUser((prevState) => {
        return {
          ...prevState,
          address: place?.name,
          lat: 0,
          lng: 0
        }
      })
      return
    }
    const location = place.geometry.location
    setUser((prevState) => {
      validateForm({
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng()
      })
      return {
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng()
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }
  const validateForm = (user) => {
    setCloudPayrollIdError(!user.payroll_id || !hasNoSpaces(user.payroll_id))
    setEmailError(user.email.length >= 1 && !isValidEmail(user.email))
    setPhoneNumberError(user.phone_number.length >= 1 && !isValidPhoneNumber(user.phone_number))
  }
  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    setUser((prevState) => {
      validateForm({
        ...prevState,
        email: value,
      });
      return {
        ...prevState,
        email: value,
      };
    })
  }
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value
    setUser((prevState) => {
      validateForm({
        ...prevState,
        phone_number: value,
      });
      return {
        ...prevState,
        phone_number: value,
      };
    })
  }
  const handleCloudPayrollIdChange = (e) => {
    const value = e.target.value
    setUser((prevState) => {
      cloudPayrollIdCheck(value)
      return {
        ...prevState,
        payroll_id: value,
      };
    })
  }
  const cloudPayrollIdCheck = async (value) => {
    get('/admin/educators/cloud_payroll_check', null, { payroll_id: value })
      .then((res) => {
        if (res.exists) {
          setCloudPayrollIdError(true)
          setError('CloudPayroll Employee ID has already been used with another QuickCare record')
        } else {
          setCloudPayrollIdError(value.length >= 1 && !hasNoSpaces(value))
          setError(null)
        }
      })
      .catch((e) => {
        setError(e.error)
      })
  }


  const handlePlaceInput = (e) => {
    setUser((prevState) => {
      validateForm({
        ...prevState,
        address: e.target.value,
        lat: 0,
        lng: 0
      });
      return {
        ...prevState,
        address: e.target.value,
        lat: 0,
        lng: 0
      };
    });
  };
  const isValidAddress = (user) => {
    if (user.address) {
      return user.lat !== 0 && user.lng !== 0
    }
    return true
  }
  
  const isUnqualified = useMemo(() => {
    return user?.qualification === qualificationLevelId.UNQUALIFIED;
  }, [user?.qualification]);


  const handlePreviousQualificationChange = (event) => {
      setUser((prevState) =>
      {
        return {
          ...prevState,
          previous_qualification: event.target.value
        }
      }
    )
  }
  const getPreviousQualificationOptions = () => {
    const highestQualificationId = user?.qualification;
    if (highestQualificationId === qualificationLevelId.DIPLOMA_TOWARD) {
      return [
        { id: qualificationLevelId.NONE, qualification: 'None' },
        { id: qualificationLevelId.CERT3, qualification: 'Cert III' },
      ]
    } else if (highestQualificationId === qualificationLevelId.ECT_TOWARRD) {
      return [
        { id: qualificationLevelId.NONE, qualification: 'None' },
        { id: qualificationLevelId.CERT3, qualification: 'Cert III' },
        { id: qualificationLevelId.DIPLOMA, qualification: 'Diploma' },
      ]
    }
    return []
  }

  const isQualificationInLevels = (levels, qualificationId) => {
    return levels.includes(qualificationId);
  };

  const isEctQualification = useMemo(() => {
    return user?.qualification === qualificationLevelId.ECT;
  }, [user?.qualification]);

  const isDiplomaOrEctToward = useMemo(() => {
    return isQualificationInLevels(
      [qualificationLevelId.DIPLOMA_TOWARD, qualificationLevelId.ECT_TOWARRD],
      user?.qualification
    );
  }, [user?.qualification]);

  const isCertificationOrDiploma = useMemo(() => {
    return isQualificationInLevels(
      [qualificationLevelId.CERT_TOWARD, qualificationLevelId.CERT3, qualificationLevelId.DIPLOMA],
      user?.qualification
    );
  }, [user?.qualification]);

  const highestQualificationWidth = useMemo(() => {
    if (isUnqualified) return 12;
    if (isCertificationOrDiploma) return 8;
    return 5;
  }, [isUnqualified, isCertificationOrDiploma]);


  const isFormValid = useCallback(() => {
    const isValidFields = user.first_name.trim() !== '' &&
      user.last_name.trim() !== '' &&
      user.email.trim() !== '' &&
      isValidAddress(user) &&
      !emailError &&
      !phoneNumberError &&
      (!isAdmin || (isAdmin && !cloudPayrollIdError)) &&
      user.qualification !== null

    const latestQualificationDate = user?.latest_qualification_date
    const ectLevel = user?.ect_level
    const milestone_passed = user?.milestone_passed
    const previousQualification = user?.previous_qualification

    if (isCertificationOrDiploma && latestQualificationDate) {
      return isValidFields
    }
    if (isEctQualification && latestQualificationDate && ectLevel) {
      return isValidFields
    } 
    if (isDiplomaOrEctToward && milestone_passed !== undefined && milestone_passed !== '' && previousQualification && latestQualificationDate) {
      return isValidFields
    }
    if (isUnqualified) {
      return isValidFields
    }

    return false;
  }, [cloudPayrollIdError, emailError, isAdmin, isCertificationOrDiploma, isDiplomaOrEctToward, isEctQualification, isUnqualified, phoneNumberError, user])
  
  function clearFormCreate() {
    setUser(defaultUser)
    setEmailError(false)
    setPhoneNumberError(false)
    setCloudPayrollIdError(false)
  }

  React.useEffect(() => {
    clearFormCreate()
  }, [open])

  React.useEffect(() => {
    setDisableSave(!isFormValid(user, isCertificationOrDiploma, isEctQualification, isDiplomaOrEctToward))
  }, [isCertificationOrDiploma, isEctQualification, isDiplomaOrEctToward, user, isFormValid])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box sx={modalBoxStyles}>
        <Typography variant="h5">
          {error ? 'Please contact Quickcare' : 'Invite an educator'}
        </Typography>
        <p>
          <Typography color={error ? 'error' : null}>
            {error ??
              'Enter the email for the educator you wish to invite. They will receive an email to download the app and set up their profile.'}
          </Typography>
        </p>
        <PaddingBox />
        <form onSubmit={handleSubmit}>
          <CommonTextField
            label="First Name"
            value={user.first_name}
            onChange={(e) =>
              setUser((prevState) => {
                validateForm({
                  ...prevState,
                  first_name: e.target.value
                })
                return {
                  ...prevState,
                  first_name: e.target.value
                }
              })
            }
          />
          <PaddingBox />
          <CommonTextField
            label="Last Name"
            value={user.last_name}
            onChange={(e) =>
              setUser((prevState) => {
                validateForm({
                  ...prevState,
                  last_name: e.target.value
                })
                return {
                  ...prevState,
                  last_name: e.target.value
                }
              })
            }
          />
          <PaddingBox />
          <CommonTextField
            label="Email"
            type="email"
            value={user.email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <PaddingBox />
          <CommonTextField
            label="Phone number"
            type="tel"
            value={user.phone_number}
            onChange={handlePhoneNumberChange}
            error={phoneNumberError}
          />
          <PaddingBox />
          <GoogleInput
            onPlaceSelected={onPlaceSelected}
            value={user.address}
            onInput={handlePlaceInput}
          />
          {isValidAddress(user) ? null : (
            <Typography color="error">Please select a valid address</Typography>
          )}
          {isAdmin ? <PaddingBox /> : null}
          {isAdmin ? (
            <CommonTextField
              label="Cloud Payroll ID"
              type="text"
              value={user.payroll_id}
              onChange={handleCloudPayrollIdChange}
              error={cloudPayrollIdError}
            />
          ) : null}
          <PaddingBox />

          <Grid container spacing={2}>
            <Grid item xs={highestQualificationWidth}>
              <FormControl fullWidth>
                <InputLabel id="qualification-label">
                  Qualification
                </InputLabel>
                <Select
                  id="qualification"
                  value={user.qualification}
                  label="Highest Qualification"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prevState) => {
                      const previousQualification =
                        e.target.value ===
                          qualificationLevelId.DIPLOMA_TOWARD ||
                        e.target.value === qualificationLevelId.ECT_TOWARRD
                          ? qualificationLevelId.NONE
                          : prevState.previous_qualification
                      return {
                        ...prevState,
                        qualification: e.target.value,
                        previous_qualification: previousQualification,
                        latest_qualification_date: null,
                        milestone_passed: '',
                        ect_level: e.target.value !== qualificationLevelId.ECT? null: prevState.ect_level
                      }
                    })
                  }
                >
                  {qualificationLevels.map((level) => (
                    <MenuItem key={level.id} value={level.id}>
                      {level.qualification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <PaddingBox />
            </Grid>
          {isEctQualification && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="ect-level-label" color="success">
                  ECT Level
                </InputLabel>
                <Select
                  id="ectLevel"
                  value={user?.ect_level ?? ''}
                  variant="outlined"
                  label="ECT Level"
                  color="success"
                  onChange={(event) =>
                    setUser((prevState) => {
                      return {
                        ...prevState,
                        ect_level: event.target.value
                      }                    
                    })
                  }
                >
                  {ectLevelList.map((level) => (
                    <MenuItem key={level.value} value={level.value}>
                      {level.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            <PaddingBox />
            </Grid>
          )}
          {!isUnqualified && (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <DatePickerTextField
                  id="qualificationDate"
                  value={user?.latest_qualification_date}
                  label={getQualificationDateLabel(user?.qualification)}
                  variant="outlined"
                  color="success"
                  onChange={(e) =>
                    setUser((prevState) => {
                      return {
                        ...prevState,
                        latest_qualification_date: e.target.value === 'Invalid Date'? null: e.target.value
                      }
                    })
                  }
                />
              </FormControl>
              <PaddingBox />
            </Grid>
          )}
          {isDiplomaOrEctToward && (
            <PercentCompleteItem 
              xs={3}
              milestone_passed={user?.milestone_passed === null ? '': user?.milestone_passed}
              onChange={(e) =>
                setUser((prevState) => {
                  return {
                    ...prevState,
                    milestone_passed: e.target.value
                  }
                })
              }
              percentCompletes={percentCompletes}
            />
          )}
          {isDiplomaOrEctToward && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="previous-qualification-label" color="success">
                  Previous Qualification
                </InputLabel>
                <Select
                  id="previousQualification"
                  value={user?.previous_qualification || qualificationLevelId.NONE}
                  label="Previous Qualification"
                  variant="outlined"
                  color="success"
                  onChange={handlePreviousQualificationChange}
                >
                  {getPreviousQualificationOptions().map((qualification) => (
                    <MenuItem key={qualification.id} value={qualification.id} dense>
                      {qualification.qualification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          
          </Grid>
          <br />
          <br />
          <Row>
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <PrimaryButton text={'Invite'} isDisabled={disableSave} />
            )}
            <PaddingBox />
            {isLoading ? null : (
              <CloseButton onClick={handleClose} text={'Cancel'} />
            )}
          </Row>
        </form>
      </Box>
    </Modal>
  )
}

export default OrgEducatorInviteForm
