import {
  Box,
  // eslint-disable-next-line no-unused-vars
  Button,
  CircularProgress,
  FormControl,
  Modal,
} from "@mui/material";
import { modalBoxStyles } from "../../../../reusable/ModalStyles";
import { useCallback, useEffect, useRef, useState } from "react";
import { PaddingBox, SpacedRow } from "../../../../reusable/Scaffolds";
import theme from "../../../../../utils/theme";
import { CloseButton, PrimaryButton } from "../../../../reusable/Buttons";
import { post, postFile } from "../../../../../utils/api";
import { AddPhotoAlternate, Cancel } from "@mui/icons-material";
import { Row } from "../../../../Layout";
import { M3BodyMedium, M3TitleLarge } from "../../../../reusable/TextStyles";
import { isValidEmail, isValidPhone } from "../../../../../utils/regex";
import { GoogleInput } from "../../../../reusable/inputs/google_input";
import { ERROR, LOADING } from "../../../../../utils/constants";
import { CommonTextField } from "../../../../reusable/TextField";
const _ = require("lodash");
const defaultCentreValidate = {
  name: "",
  description: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  address: "",
};
export default function CentresModal({ centre, open, onClose, orgId, editable, onCentreUpdate }) {
  const formattedCentre = useCallback(
    () => {
      const formatCentre = centre
      ? {
          ...centre,
          images: centre?.images ?? [],
          address: centre?.address.address,
          lat: centre?.address.lat,
          lng: centre?.address.lng,
        }
      : {
          name: "",
          organisation_id: orgId,
          description: "",
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          images: [],
          address: "",
          lat: 0,
          lng: 0,
        }
      delete formatCentre.assignment_group
      return formatCentre
    },
    [centre, orgId]
  );
  const [disableSave, setDisableSave] = useState(true);
  const [centreEditing, setCentreEditing] = useState(centre ?? {});
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [validateMessage, setValidateMessage] = useState("")
  const validateForm = (centreCheck) => {
    let tooMuchCharacters = false;
    if(centreCheck.description){
      tooMuchCharacters = centreCheck.description.length > 1023;
      setValidateMessage( tooMuchCharacters? "Too many characters in the description" : "")
    }

    setDisableSave(
      _.isEqual(centreCheck, formattedCentre()) ||
        !centreCheck.name ||
        !centreCheck.address ||
        !centreCheck.lat ||
        tooMuchCharacters ||
        (centreCheck.contact_email &&
          !isValidEmail(centreCheck.contact_email)) ||
        (centreCheck.contact_name &&
          !isValidPhone(centreCheck.contact_phone)) ||
        !centreCheck.lng
    );
    setValiDateState(defaultCentreValidate);
    if (centreCheck.contact_email && !isValidEmail(centreCheck.contact_email)) {
      setValiDateState((prevState) => ({
        ...prevState,
        contact_email: "Invalid email",
      }));
    }
    if (centreCheck.contact_phone && !isValidPhone(centreCheck.contact_phone)) {
      setValiDateState((prevState) => ({
        ...prevState,
        contact_phone: "Invalid phone number",
      }));
    }
    if (!centreCheck.name) {
      setValiDateState((prevState) => ({
        ...prevState,
        name: "Required",
      }));
    }
    if (
      centreCheck.lat === 0 ||
      centreCheck.lng === 0 ||
      !centreCheck.address
    ) {
      setValiDateState((prevState) => ({
        ...prevState,
        address: "Address not found",
      }));
    }
  };
  const [valiDateState, setValiDateState] = useState(defaultCentreValidate);
  let urlToRemoveDB = [];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCentreEditing({ ...centreEditing, [name]: value });
    validateForm({ ...centreEditing, [name]: value });
  };

  const handleImageFileChange = async (e) => {
    const files = Array.from(e.target.files);

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setUploadingFiles((prevState) => [
          ...prevState,
          {
            file,
            src: reader.result,
            progress: LOADING,
          },
        ]);
      };
    }
    for (const file of files) {
      try {
        const response = await postFile("/upload", file);
        if (response.url) {
          setCentreEditing((prevState) => {
            validateForm({
              ...prevState,
              images: [...prevState.images, response.url],
            });
            return {
              ...prevState,
              images: [...prevState.images, response.url],
            };
          });
          setUploadingFiles((prevState) =>
            prevState.filter((f) => f.file !== file)
          );
        }
      } catch (error) {
        setUploadingFiles((prevState) => [
          ...prevState.filter((f) => f.file !== file),
          {
            file: file,
            progress: ERROR,
          },
        ]);
      }
    }
  };
  const inputFile = useRef(null);

  const onPlaceSelected = (place) => {
    if (!place?.geometry){
      setCentreEditing((prevState) => {
        validateForm({
          ...prevState,
          address: place?.name,
          lat: 0,
          lng: 0,
        });
        return {
          ...prevState,
          address: place?.name,
          lat: 0,
          lng: 0
        };
      });
      return;
    }
       
    const location = place.geometry.location;
    setCentreEditing((prevState) => {
      validateForm({
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
      });
      return {
        ...prevState,
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
      };
    });
  };

  const handleRemoveImage = (urlToRemove) => {
    setCentreEditing((prevState) => {
      validateForm({
        ...prevState,
        images: prevState.images.filter((url) => url !== urlToRemove),
      });
      return {
        ...prevState,
        images: prevState.images.filter((url) => url !== urlToRemove),
      };
    });
    urlToRemoveDB.push(urlToRemove);
  };

  const handleSaveCentre = () => {
    post(
      `/manage/organisations/${orgId}/centres${
        centreEditing.id ? `/${centreEditing.id}` : ""
      }`,
      {
        ...centreEditing,
        address: centreEditing.address,
        lat: centreEditing.lat,
        lng: centreEditing.lng,
      }
    )
      .then((response) => {
        removeItemOnDB();
        setDisableSave(true);
        setCentreEditing(formattedCentre);
        onCentreUpdate(response);
        onClose(true);
      })
      .catch(console.error);
  };

  const removeItemOnDB = async () => {
    for (const url of urlToRemoveDB) {
      post("/remove_upload", { url })
        .then(() => {
          console.log("removed");
        })
        .catch(console.error);
    }
    urlToRemoveDB = [];
  };

  useEffect(() => {
    setCentreEditing(formattedCentre);
  }, [formattedCentre]);
 const excludedFields = [
   'id',
   'organisation_id',
   'images',
   'has_assignment_group',
   'lat',
   'lng',
   'address'
 ]
  return (
    <Modal
      open={open}
      onClose={() => {
        setDisableSave(true)
        onClose(false)
      }}
      sx={{ zIndex: 999 }}
    >
      <Box sx={modalBoxStyles}>
        {editable ? (
          <M3TitleLarge>{centre ? 'Edit' : 'Add'} centre</M3TitleLarge>
        ) : null}
        <PaddingBox />
        <FormControl fullWidth>
          {Object.keys(centreEditing).map((key) => {
            if (excludedFields.includes(key)) return null // Skip these fields
            return (
              <div key={key}>
                <CommonTextField
                  key={key}
                  variant="outlined"
                  margin="normal"
                  error={valiDateState[key] !== ''}
                  helperText={valiDateState[key]}
                  isMultiline={key === 'description'}
                  rows={key === 'description' ? 3 : 1}
                  fullWidth
                  label={
                    key === 'name'
                      ? 'Centre name'
                      : key.replace(/_/g, ' ').charAt(0).toUpperCase() +
                        key.replace(/_/g, ' ').slice(1)
                  }
                  name={key}
                  isDisable={!editable}
                  value={centreEditing[key]}
                  onChange={handleInputChange}
                />
                <PaddingBox />
              </div>
            )
          })}
          <input
            type="file"
            hidden
            ref={inputFile}
            multiple
            onChange={handleImageFileChange}
          ></input>
          {editable ? (
            <GoogleInput
              error={valiDateState.address !== ''}
              helperText={valiDateState.address}
              onPlaceSelected={onPlaceSelected}
              value={centreEditing.address}
              onInput={handleInputChange}
            />
          ) : (
            <CommonTextField
              value={centreEditing.address}
              label="Address"
              isDisable
            ></CommonTextField>
          )}
          <PaddingBox />
          <ImageUploadingSection
            editable={editable}
            handleRemoveImage={handleRemoveImage}
            centreEditing={centreEditing}
            uploadingFiles={uploadingFiles}
            inputFile={inputFile}
          />
        </FormControl>
        <PaddingBox />
        {validateMessage && (
          <M3BodyMedium style={{ color: 'red' }}>
            {validateMessage}
          </M3BodyMedium>
        )}
        {validateMessage && <PaddingBox />}
        <SpacedRow>
          <CloseButton
            onClick={() => {
              setDisableSave(true)
              onClose(false)
            }}
            text={'Cancel'}
          />
          {editable ? (
            <PrimaryButton
              isDisabled={disableSave}
              text={centre ? 'Update' : 'Add'}
              onClick={handleSaveCentre}
            />
          ) : null}
        </SpacedRow>
      </Box>
    </Modal>
  )
}


function ImageUploadingSection({
  handleRemoveImage,
  centreEditing,
  uploadingFiles,
  inputFile,
  editable
}) {
  return (
    <div
      style={{
        borderStyle: "dashed",
        borderRadius: "8px",
        borderColor: theme.palette.text,
        padding: "8px",
        overflow: "auto",
      }}
    >
      <SpacedRow>
        <Row>
          {(!centreEditing.images || centreEditing.images?.length === 0) &
          (uploadingFiles.length === 0) ? (
            <div>
              <M3BodyMedium
                onClick={() => {
                  inputFile.current.click();
                }}
                style={{ color: theme.palette.info.main }}
              >
                {editable? 'No images click here to upload a image': 'No images available'}
              </M3BodyMedium>
            </div>
          ) : null}
          {(centreEditing.images || []).map((url, index) => (
            <div
              key={index}
              style={{
                width: "100px",
                height: "auto",
                paddingLeft: "8px",
                position: "relative",
              }}
            >
              <div
                onClick={() => handleRemoveImage(url)}
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  width: "24px",
                  height: "24px",
                  borderRadius: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  right: 0,
                }}
              >
                <Cancel></Cancel>
              </div>
              <img
                src={url}
                alt={`Selected ${index}`}
                style={{
                  width: "100px",
                  height: "auto",
                }}
              />
            </div>
          ))}
          {uploadingFiles.map((file, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                width: "100px",
                paddingLeft: "8px",
                height: "auto",
              }}
            >
              <CircularProgress
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                color="info"
              ></CircularProgress>
              <div
                style={{
                  width: "100px",
                  position: "absolute",
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              ></div>
              <img
                src={file.src}
                style={{ width: "100px", height: "auto" }}
                alt=""
              />
            </div>
          ))}
        </Row>
        {editable?<AddPhotoAlternate
          onClick={() => {
            inputFile.current.click();
          }}
          style={{ cursor: "pointer" }}
        ></AddPhotoAlternate>: null}
      </SpacedRow>
    </div>
  );
}
