
import React from 'react'
import { Select, MenuItem, InputLabel, Grid, FormControl } from "@mui/material"
import { DropDownButton }  from "./Buttons";

export function PercentCompleteItem({ xs, milestone_passed, onChange, percentCompletes }) {
  return (
    <Grid item xs={xs}>
      <FormControl fullWidth>
        <InputLabel id="percent-complete-label">
          % Complete
        </InputLabel>
        <Select
          id="percentComplete"
          value={percentCompletes.length > 0 ? milestone_passed : ''}
          label="% Complete"
          variant="outlined"
          onChange={onChange}
          >
          {percentCompletes.map((option) => (
            <MenuItem key={option.id} value={option.value} dense>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}


export function PreviousQualificationItem({ xs, currentValue, onChange, options}) {
  return (
    <Grid item xs={xs}>
      <DropDownButton
        placeholder="Previous qualification"
        currentValue={currentValue}
        onChange={onChange}
        options={options}
      />
  </Grid>
  )
}

export function EctLevelItem ({ xs, currentValue, onChange, options}) {
  return (
    <Grid item xs={xs}>
      <DropDownButton
        placeholder={'ECT level'}
        currentValue={currentValue}
        onChange={onChange}
        options={options}
      />
    </Grid>
  )
}