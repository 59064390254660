import React from 'react'
import { Box, Button } from '@mui/material'
import { ArrowRight } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import theme from '../../../../../utils/theme'
import { M3TitleMedium } from '../../../../reusable/TextStyles'

const PolicyItem = ({ name, content, onEditClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        marginBottom: '12px',
        cursor: 'pointer',
        padding: '12px',
        color: theme.palette.onSurface.main,
        backgroundColor: theme.palette.surface.main
      }}
      onClick={onEditClick}
    >
      <div style={{ flex: 1 }}>
        {!name ? (
          <Skeleton width={'20%'}></Skeleton>
        ) : (
          <M3TitleMedium>{name}</M3TitleMedium>
        )}
      </div>
      <Button onClick={onEditClick} sx={{ minWidth: 'auto' }}>
        <ArrowRight sx={{ color: 'black' }} />
      </Button>
    </Box>
  )
}

export default PolicyItem
