import React, { useContext } from "react";
import { Box, Button, Modal } from "@mui/material";
import { modalBoxStyles } from "../reusable/ModalStyles";
import { Column, PaddingBox, Row, SpacedRow } from "../reusable/Scaffolds";
import { M3BodyMedium, M3TitleLarge, M3TitleSmall } from "../reusable/TextStyles";
import { post } from "../../utils/api";
import { OrgContext } from "../../utils/context";
import ErrorIcon from '@mui/icons-material/Error';
import theme from "../../utils/theme";
import { stringAvatar } from "../../utils/helper_functions";
import { CustomAvatar } from "../Layout";
import { CloseButton } from "../reusable/Buttons";

const ConfirmBlacklistForm = ({
  open,
  setOpen,
  setCloseModalAdd,
  educatorInfo,
  educatorQualification,
  setAlert,
  reason,
  refreshDataShift,
}) => {
  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddBlacklist = () => {
    post(`/manage/organisations/${org_id}/educators/black_listed`, {
      educator_id: educatorInfo.id,
      reason: reason,
    })
    .then((res) => {
      handleClose()
      setCloseModalAdd(false)
      refreshDataShift()
      setAlert(res.message)
    })
    .catch((error) => {
      handleClose()
      setCloseModalAdd(false)
      setAlert(error.message, 'error')
    })
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box sx={{ ...modalBoxStyles, maxWidth: 400 }}>
        <ErrorIcon id="icon-menu" sx={{ color: theme.colors.colorB33F5B }} />
        <PaddingBox />
        <M3TitleLarge>Blacklist this educator?</M3TitleLarge>
        <PaddingBox />
        <M3BodyMedium>
          This educator won't be able to see any other shifts from your
          organisation and accept them in the future.
        </M3BodyMedium>
        <PaddingBox />
        <Row>
          <CustomAvatar
            {...stringAvatar(
              educatorInfo.first_name + ' ' + educatorInfo.last_name
            )}
            src={educatorInfo.avatar_url}
            sx={{ width: 50, height: 50 }}
          />
          <PaddingBox />
          <Column>
            <M3TitleSmall>
              {educatorInfo.first_name}{' '}
              {educatorInfo.prefered_name && `(${educatorInfo.prefered_name}) `}
              {educatorInfo.last_name}
            </M3TitleSmall>
            <M3BodyMedium>
              {educatorQualification ? educatorQualification : 'n/a'}
            </M3BodyMedium>
            <M3TitleSmall>
              Reason: <M3BodyMedium>{reason ? reason : 'n/a'}</M3BodyMedium>
            </M3TitleSmall>
          </Column>
        </Row>

        <PaddingBox />
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={handleClose} text={'Cancel'} />
          <Button
            type="submit"
            variant="contained"
            color="info"
            disabled={reason === ''}
            onClick={handleAddBlacklist}
          >
            Blacklist
          </Button>
        </SpacedRow>
      </Box>
    </Modal>
  )
};

export default ConfirmBlacklistForm;
