import React from 'react';
import { Button, Container, } from '@mui/material';
import {  M3HeadlineSmall, M3LabelMedium } from '../reusable/TextStyles';
import { PaddingBox } from '../reusable/Scaffolds';

const EducatorAccount  = ({onLogout}) => {
  return (
    // Centered container
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
     <M3HeadlineSmall>Hi your account is not suitable for our web application</M3HeadlineSmall> 
     <PaddingBox></PaddingBox>
     <M3LabelMedium>Please download our mobile application to use our services</M3LabelMedium>
     <PaddingBox></PaddingBox>
     <Button variant="contained" color="primary" onClick={onLogout}>
        Logout
     </Button>
    </Container>
  );
};

export default EducatorAccount;
