import {
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  // InputLabel,
  Box,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material";
import { withTheme } from "@mui/styles";
import PlusIcon from "@mui/icons-material/Add";
import { M3Body, M3BodyMedium } from "./TextStyles";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import theme from "../../utils/theme";
export function AddButton({ onClick, text }) {
  return (
    <CreateButton
      variant="contained"
      color="info"
      startIcon={<PlusIcon />}
      onClick={onClick}
    >
      <M3Body>{text}</M3Body>
    </CreateButton>
  );
}
export function PrimaryButton({ onClick, text, isDisabled = false, isLoading = false }) {
  return (
    <CreateButton
      disabled={isDisabled}
      variant="contained"
      type="submit"
      onClick={onClick}
      color="info"
    >
      {isLoading ? (
        <CircularProgress size={24} color="white"></CircularProgress>
      ) : (
        <M3Body>{text}</M3Body>
      )}
    </CreateButton>
  )
}

export function SuccessButton({ onClick, text, isDisabled }) {
  return (
    <SuccessButtonStyled
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
    >
      {text}
    </SuccessButtonStyled>
  );
}

export function RemoveButton({ onClick, text, isDisabled }) {
  return (
    <EndButton
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
    >
      <M3Body>{text}</M3Body>
    </EndButton>
  )
}
export function CloseButton({ onClick, text }) {
  return (
    <CancelButton
      onClick={onClick}
      variant="contained"
    >
      <M3Body>{text}</M3Body>
    </CancelButton>
  )
}

const CancelButton = muiStyled(withTheme(Button))`
    text-transform: none;
    padding: 8px 16px;
      border-radius: 28px;
    background-color: ${(props) => props.theme.palette.secondary.main} !important;
    color: ${(props) => props.theme.palette.secondary.onSecondary} !important;
    &:hover {
      opacity: 0.8;
    }
    `;

const EndButton = muiStyled(withTheme(Button))`
    text-transform: none;
    border-radius: 28px;
    padding: 8px 16px;
    background-color: ${(props) => props.theme.palette.tertiaryContainer.main} !important;
    color: ${(props) => props.theme.palette.tertiaryContainer.onTertiary} !important;
    &:hover {
      opacity: 0.8;
    }
    `;
const CreateButton = muiStyled(withTheme(Button))`
    text-transform: none;
    border-radius: 28px;
    padding: 8px 16px;
    &:hover {
      opacity: 0.8;
    }
`;
const SuccessButtonStyled = muiStyled(withTheme(Button))`
  text-transform: none;
  border-radius: 28px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  &:hover {
    background-color: darkgreen;
    opacity: 0.8;
  }
`;

const SelectCustom = muiStyled(withTheme(Select))`
    height: 32px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-left: 16px;
    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color:rgba(0, 0, 0, 0.2);
        &:hover {
            border-color:rgba(0, 0, 0, 0.8)
        }
    }
    &.MuiInputBase-root .Mui-disabled {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
         color: rgba(0, 0, 0, 0.8)
    }
`;

export function FilterButton({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
      onClick={onClick}
      autoWidth
      style={{ borderRadius: "8px" }}
      disabled={isDisabled}
      placeholder={placeholder}
      sx={{
        borderRadius: 2,
      }}
    >
      <MenuItem disabled value={""}>
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id} dense>
          {option.name}
        </MenuItem>
      ))}
    </SelectCustom>
  );
}

export function FilterButtonMultiple({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
      onClick={onClick}
      renderValue={(selected) => {
        if (selected.length === 0) return placeholder;  
        return `${selected.length} location${selected.length>1?'s':''} selected`;
      }}
      autoWidth
      multiple
      style={{ borderRadius: "8px" }}
      disabled={isDisabled}
      placeholder={placeholder}
      sx={{
        borderRadius: 2,
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={currentValue.indexOf(option.id) > -1} />
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </SelectCustom>
  );
}

export function FilterEmpty({
  currentValue,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onClick={onClick}
      autoWidth
      style={{ borderRadius: "8px" }}
      disabled={isDisabled}
      placeholder={placeholder}
      sx={{
        borderRadius: 2,
      }}
    >
      <MenuItem disabled value={""}>
        {placeholder}
      </MenuItem>
    </SelectCustom>
  );
}

export const BackButton = ({ title, onClick }) => {
  return (
    <Button
      startIcon={<KeyboardBackspaceIcon />}
      onClick={onClick}
      sx={{
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 500,
        color: theme.palette.info.main,
      }}
    >
      {title}
    </Button>
  );
};

export function DropDownButton({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <FormControl
      fullWidth
      variant={isDisabled ? "filled" : "outlined"}
    >
      <InputLabel id="demo-simple-select-filled-label">
        {placeholder}
      </InputLabel>
      <Select
        value={currentValue}
        onChange={onChange}
        onClick={onClick}
        disabled={isDisabled}
        placeholder={placeholder}
        label={placeholder}
      >
        {options}
      </Select>
    </FormControl>
  );
}



export function ApprovalCheckbox ({ isChecked = false, onChange, content }){
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        sx={{ color: isChecked ? theme.palette.primary.main : '' }}
      />
       <M3BodyMedium>{content}</M3BodyMedium>
    </Box>
  );
};