import React, { useCallback, useEffect, useState } from 'react'
import { PaddingBox, SpacedRow } from '../../reusable/Scaffolds'
import { SearchBox } from '../../reusable/TextField'
import { get, put } from '../../../utils/api'
import { debounce } from '../../../utils/helper_functions'
import { ApplicationItem, ApplicationSkeleton } from './components/application_item'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import _ from 'lodash'
import { ApplicationReviewModal } from './components/application_review_modal'
import { Box } from '@mui/material'
import { M3TitleLarge } from '../../reusable/TextStyles'
const ApplicationsManage = ({setAlert}) => {
  const [applications, setApplications] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState(null)
  const fetchApplications = useCallback(
    async (searchText) => {
      const params = {
        name_text: searchText ?? ''
      }
      setIsFetching(true)
      const convertedParams = Object.keys(params)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      get(`/admin/applications${convertedParams ? '?' + convertedParams : ''}`)
        .then((data) => {
          setIsFetching(false)
          const sortedData = _.orderBy(data, ['unseen'], ['desc'])
          setApplications(sortedData)
        })
        .catch((error) => {
          setAlert({ type: 'error', message: error.message })
          setIsFetching(false)
        })
    },
    [setAlert]
  )
  const debouncedFetchApplications = (searchText) => {
    debounce(fetchApplications(searchText), 200)
  }
  const onClickItem = (application) => {
    setSelectedApplication(application)
    if (application.unseen)
    updateReadStatus(application.id)
  }
  const updateReadStatus = (id) => {
    put(`/admin/applications/${id}/seen`)
      .then((data) => {
        setApplications((prev) => {
          const updatedApplications = prev.map((application) => {
            if (application.id === id) {
              return { ...application, unseen: false }
            }
            return application
          })
          return updatedApplications
        })
      })
      .catch((error) => {
       setAlert({ type: 'error', message: error.message })
      })
  }
  const closeReviewModal = () => {
    setSelectedApplication(null)
    fetchApplications()
  }
  useEffect(() => {
    fetchApplications()
  }, [fetchApplications])

  return (
    <div>
      <ApplicationReviewModal open={selectedApplication !== null} closeModal={closeReviewModal} people={selectedApplication} setAlert={setAlert} />
      <SpacedRow>
        <M3TitleLarge>Applications</M3TitleLarge>
      </SpacedRow>
      <SearchBox
        placeholder={'Search for an educator'}
        onChange={(e) => {
          setSearchText(e.target.value)
          debouncedFetchApplications(e.target.value)
        }}
        value={searchText}
      ></SearchBox>
      <PaddingBox />
      {
      
      isFetching ? (
        <ApplicationSkeleton></ApplicationSkeleton>
      ) :
      applications.length === 0 ? (
        <Box>There are no applications</Box>
      ) : (
        <AutoSizer style={{ height: '60vh' }}>
          {({ height, width }) => {
            return (
              <List
                className="List"
                height={height}
                itemCount={applications ? applications.length : 0}
                itemSize={110}
                width={width}
              >
                {({ index, style }) => {
                return (
                  <ApplicationItem
                    style={style}
                    key={index}
                    people={applications[index]}
                    onClick={()=> onClickItem(applications[index])}
                  ></ApplicationItem>
                )
                }}
              </List>
            )
          }}
        </AutoSizer>
      )}
    </div>
  )
}

export default ApplicationsManage
