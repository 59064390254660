import { usePlacesWidget } from "react-google-autocomplete";
import { CommonTextField } from "../TextField";

export const  GoogleInput = ({ onPlaceSelected, value, onInput, error, helperText }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";
    const { ref } = usePlacesWidget({
      apiKey,
      onPlaceSelected,
      options: {
        types: ["address"],
        componentRestrictions: { country: "au" },
      },
    });
    return (
      <CommonTextField
        value={value}
        error={error}
        helperText={helperText}
        onChange={onInput}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Address"
        name="address"
        inputRef={ref}
      />
    );
  }
  