import { List, Skeleton } from "@mui/material";
import { ViewModeOrgDetailItem } from "./view_mode_org_detail_item";
import { PaddingBox, Row } from "../../../../reusable/Scaffolds";

export const OrgDetailSkeletonLoading = () => {
  return (
    <List>
        <Row key={-1}>
            <Skeleton variant="circular" width={64} height={60} />
            <PaddingBox />
            <Skeleton variant="text" width={200} />
        </Row>
      {[...Array(5)].map((_, index) => (
        <ViewModeOrgDetailItem key={index} />
      ))}
    </List>
  );
};
