import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../utils/theme";

const StyledBadge = styled(Badge)(() => ({
  left: 12,
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.tertiary.onTertiary,
}))
const CustomBadge = ({ children, badgeContent }) => {
  return (
    <StyledBadge badgeContent={badgeContent} max={999999}>
      {children}
    </StyledBadge>
  );
}

export default CustomBadge;
